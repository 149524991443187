import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UploadProps } from './UploadMenu';

const CustomAccordionContainer = styled(Box)(({ theme }) => ({
    '& .MuiAccordion-root': {
        marginBottom: theme.spacing(1),
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiAccordionSummary-root': {
        backgroundColor: '#CFDBE2',
        '&.Mui-expanded': {
            minHeight: 48,
        },
    },
    '& .MuiAccordionSummary-content': {
        margin: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0.5),
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: theme.spacing(2),
    },
}));

const AccordionUpload: React.FC<UploadProps> = ({ tabs }) => {
    const [expanded, setExpanded] = React.useState<string | false>('Sample Info');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <CustomAccordionContainer>
                {tabs.map((tab, index) => (
                    <Accordion
                        expanded={expanded === tab.label}
                        onChange={handleChange(tab.label)}
                        key={index}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>{tab.label}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {tab.components.map((Component, componentIndex) => (
                                <Box key={componentIndex} margin={1}>
                                    {Component}
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </CustomAccordionContainer>
        </Box>
    );
};

export default AccordionUpload;
