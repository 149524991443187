import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import logosfacolor from '../../../asset/Logo/logoSFA.png';
import sfaLogoWhite from '../../../asset/Logo/sfa_logo_text_monowhite.png';
import { NavLink, useLocation } from 'react-router-dom';

const AppLogo = () => {
    const location = useLocation();
    const theme = useTheme()
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

    let fontSize;
    let title;

    if (isXSmallScreen) {
        fontSize = '0.9rem';
        title = 'ASN';
    } else if (isSmallScreen) {
        fontSize = '1rem';
        title = 'ASN'
    } else {
        fontSize = '1.25rem';
        title = 'Aquaculture Sensing Network'
    }

    return (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} flex={1}>
            <NavLink
                to="/"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flex: 1,
                    textDecoration: 'none'
                }}
            >
                <img src={location.pathname === '/' ? sfaLogoWhite : logosfacolor} alt='logo' style={{ height: isXSmallScreen ? 40 : 30, marginRight: 5 }} />
                <Typography
                    variant="h6"
                    noWrap
                    sx={{
                        mr: 2,
                        display: 'flex',
                        fontWeight: 700,
                        textDecoration: 'none',
                        color: location.pathname === '/' ? 'white' : 'black',
                        fontSize: fontSize
                    }}
                >
                    {title}
                </Typography>
            </NavLink>
        </Box>
    );
}

export default AppLogo;
