import { ArrowRight } from '@mui/icons-material'
import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import { breadcrumbText } from './Style';

const DocumentBreadcrumb = () => {

    const { path, setPath } = useDocumentView();

    const handleBreadcrumbClick = (index: number) => {
        const newPath = path.slice(0, index + 1);
        setPath(newPath);
    };

    const breadcrumbs = path.map((name: string, index: number) => (
        <Typography
            key={index}
            variant="caption"
            onClick={() => handleBreadcrumbClick(index)}
            sx={breadcrumbText}
        >
            {name}
        </Typography>
    ));

    return (
        <Breadcrumbs separator={<ArrowRight fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>
    )
}

export default DocumentBreadcrumb