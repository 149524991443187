import React from 'react';
import fishFarm from '../../../asset/Images/fish-farm.png'
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Text from '../../common/text/Text';

type TextContentType = {
    variant: "h2" | "body1" | "button" | "caption" | "h1" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body2" | "overline";
    text: string;
}

const LandingPageBanner = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const textContents: TextContentType[] = [
        {
            variant: "h3",
            text: "Welcome to the SFA Aquaculture Sensing Network portal (ASN)",
        },
        {
            variant: "body1",
            text: "The Aquaculture Sensing Network continuously monitors real-time water quality metrics in the designated farming areas in the Straits of Johor and Southern waters,",
        },
        {
            variant: "body1",
            text: "The aim is to safeguard farm production, ensure sustainable farm operations and monitors the impact of farming activities on the environment.",
        },
    ];

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%), url(${fishFarm})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: 0,
                top: -80,
                left: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: isSmallScreen || isMediumScreen ? 5 : 10,
                paddingRight: isSmallScreen || isMediumScreen ? 5 : 30,
            }}
        >
            <Box sx={{ textAlign: 'left' }}>
                {textContents.map(({ variant, text }, index) => (
                    <Text
                        key={index}
                        variant={variant}
                        sx={{
                            color: 'white',
                            marginBottom: variant === "h3" ? 3 : 1,
                            fontSize: isSmallScreen ? (variant === "h3" ? '1.25rem' : '12px') : isMediumScreen ? (variant === "h3" ? '1.75rem' : '14px') : (variant === "h3" ? '2.5rem' : '18px'),
                            width: '100%',
                        }}>
                        {text}
                    </Text>
                ))}
            </Box>
        </Box>
    )
}

export default LandingPageBanner;
