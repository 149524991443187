import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/auth-context/AuthContext';
import { MapViewProvider } from './context/map-context/mapContext';
import { ContactProvider } from './context/contact-context/contactContext';
import { TimeseriesViewProvider } from './context/timeseries-context/timeseriesContext';
import { EventViewProvider } from './context/event-context/EventContext';
import { DocumentViewProvider } from './context/document-context/DocumentContext';
import { UploadViewProvider } from './context/upload-context/UploadContext';
import { OidcProvider } from '@axa-fr/react-oidc';
import { DataPointViewProvider } from './context/data-point-context/DataPointContext';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';

const environment = window.location.origin;
const isProduction = environment === 'https://asn.infoatsea.com';

const oidcConfiguration = {
  client_id: isProduction
    ? 'b4266eba-50b6-44ed-a6dc-b8a1992a580b'
    : '08cab292-9a3d-424a-a133-1360f2d51278',
  redirect_uri: `${environment}/auth/callback`,
  silent_redirect_uri: `${environment}/auth/silent-callback`,
  scope: isProduction
    ? 'api://b4266eba-50b6-44ed-a6dc-b8a1992a580b/Files.Read offline_access'
    : 'api://08cab292-9a3d-424a-a133-1360f2d51278/Files.Read offline_access',
  authority: 'https://login.microsoftonline.com/common/v2.0',
  service_worker_only: false,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

const root = ReactDOM.createRoot(
  document.getElementById('root')!
);

root.render(
  <Router>
    <OidcProvider configuration={oidcConfiguration}>
      <AuthProvider>
        <MapViewProvider>
          <TimeseriesViewProvider>
            <UploadViewProvider>
              <DocumentViewProvider>
                <EventViewProvider>
                  <DataPointViewProvider>
                    <ContactProvider>
                      <App />
                    </ContactProvider>
                  </DataPointViewProvider>
                </EventViewProvider>
              </DocumentViewProvider>
            </UploadViewProvider>
          </TimeseriesViewProvider>
        </MapViewProvider>
      </AuthProvider>
    </OidcProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
