import { SxProps, Theme } from '@mui/material/styles';

export const buttonStyle: SxProps<Theme> = {
    backgroundColor: '#f58e3f',
    borderRadius: 2,
    ':hover': {
        backgroundColor: '#d47606',
        opacity: 0.8,
    },
};

export const userButtonStyle = (pathname: string): SxProps<Theme> => ({
    textTransform: 'capitalize',
    border: 'none',
    color: pathname === '/' ? 'white' : '#1E1E1E',
    '&:hover': {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
    },
});

export const menuSx = {
    mt: '60px',
};

export const userInfoStackSx = {
    direction: 'row' as const,
    justifyContent: 'flex-start',
    alignItems: 'center',
    spacing: 1,
    width: 300,
    padding: 2,
};

export const userInfoTextSx = {
    fontSize: 14,
    color: '#0d3549',
    fontWeight: 600,
};

export const userEmailTextSx = {
    color: '#89a0ae',
};

export const profileRoleSx = {
    fontSize: 14,
    color: '#0d3549',
    fontWeight: 600,
};

export const profileRoleDetailSx = {
    fontSize: 12,
    color: '#89a0ae',
};

export const menuItemTextSx = {
    marginLeft: 1,
    fontSize: 12,
    color: '#063342',
};
