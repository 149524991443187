import { Alert, Box, CircularProgress, Snackbar, Backdrop } from '@mui/material'
import React from 'react'
import ReactMapGL from '../../components/layout/map/ReactMapGL'
import MapMenu from '../../components/layout/map/MapMenu'
import { useAuth } from '../../context/auth-context/AuthContext'
import { useMapView } from '../../context/map-context/mapContext'
import MapMenuButton from '../../components/feature/map-menu-button/MapMenuButton'

const MapViewPage = () => {
  
  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth()
  const { loading } = useMapView()
  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 65px)',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'hidden',
      }}
    >
      <MapMenu />
      <MapMenuButton />
      <ReactMapGL />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  )
}

export default MapViewPage
