export interface MenuItem {
    id: number;
    title: string;
    link: string;
    roles: string[];
    alwaysAccessible?: boolean;
}

export interface UserRoles {
    roles: string[];
}

const menuBarData: MenuItem[] = [
    {
        id: 1,
        title: "Map View",
        link: "/map-view",
        roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 2,
        title: "Timeseries",
        link: "/timeseries",
        roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 3,
        title: "Upload",
        link: "/upload",
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 4,
        title: "Document",
        link: "/document",
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 5,
        title: "Event",
        link: "/event",
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 6,
        title: "Data Point",
        link: "/data-point",
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        id: 7,
        title: "Contact Us",
        link: "/contact-us",
        roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
];

const getMenuBarData = (user: UserRoles | null): MenuItem[] => {
    if (!user) {
        return menuBarData.filter(item => item.alwaysAccessible);
    }

    const userRoles = Array.isArray(user.roles) ? user.roles : [user.roles];

    return menuBarData.filter(item =>
        item.alwaysAccessible || userRoles.some(role => item.roles.includes(role))
    );
};

export { getMenuBarData };
