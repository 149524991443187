// src/context/ContactContext.tsx
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useAuth } from '../auth-context/AuthContext';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';

interface ContactContextType {
    categories: any;
    loading: boolean;
    error: string | null;
    fetchCategories: () => void;
    sendEmail: (emailData: { name: string; email: string; subject: string; category: string; enquiry: string }) => Promise<void>;
}

const ContactContext = createContext<ContactContextType | undefined>(undefined);

export const ContactProvider = ({ children }: { children: ReactNode }) => {

    const { setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
    const fetchWithAuth = useFetchWithAuth()
    const [categories, setCategories] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/ContactUs/categories', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            setCategories(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const sendEmail = async (emailData: { name: string; email: string; subject: string; category: string; enquiry: string }) => {
        try {
            const response = await fetchWithAuth('/api/ContactUs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData)
            });

            if (!response.ok) {
                setSnackbarMessage('Failed to send email');
                setSnackbarOpen(true);
                setVariant('error');
                return;
            }

            setSnackbarMessage('Email sent successfully');
            setSnackbarOpen(true);
            setVariant('success');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
            setSnackbarMessage('Failed to send email');
            setSnackbarOpen(true);
            setVariant('error');
        }
    };

    return (
        <ContactContext.Provider value={{ categories, loading, error, fetchCategories, sendEmail }}>
            {children}
        </ContactContext.Provider>
    );
};

export const useContact = () => {
    const context = useContext(ContactContext);
    if (context === undefined) {
        throw new Error('useContact must be used within a ContactProvider');
    }
    return context;
};
