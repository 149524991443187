import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Text from '../../common/text/Text';

type TextContentType = {
    variant: "h2" | "body1" | "button" | "caption" | "h1" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body2" | "overline";
    text: string;
}

const LandingPageMonitoring = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const textContents: TextContentType[] = [
        {
            variant: "body1",
            text: "The Singapore Food Agency (SFA) will diligently monitor the marine environment in the designated farming areas across Singapore's coastal areas through the Aquaculture Sensing Network (ASN) portal.",
        },
        {
            variant: "body1",
            text: "The ASN portal offers an intuitive platform to access comprehensive data gathered through our monitoring efforts. Explore and analyze the data to gain valuable insights into the environmental conditions important for the aquaculture industry.",
        },
        {
            variant: "body1",
            text: "Stay informed, empowered and engaged as we work together towards ensuring the sustainable growth and resilience of Singapore's aquaculture sector.",
        },
    ];

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: '#F88E3A',
                    zIndex: 2,
                    top: -260,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    clipPath: 'polygon(60px 0%, calc(100% - 40px) 0%, 100% 30px, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0% 100%, 0% 60px)',
                    height: 350,
                    borderBottomRightRadius: 120,
                    borderTopRightRadius: 40,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '60px',
                        left: '-20px',
                        width: '200px',
                        height: '200px',
                        backgroundColor: '#F88E3A',
                        borderRadius: '50%',
                        border: '2px dashed rgba(255, 255, 255, 0.5)',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        borderTop: '2px dashed rgba(255, 255, 255, 0.5)',
                        top: '70px',
                        left: 0,
                        zIndex: 1
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '30px',
                        zIndex: 3,
                        width: '100%',
                        textAlign: 'left',
                    }}
                >
                    <Text
                        variant='h4'
                        sx={{
                            color: 'white',
                            fontSize: isSmallScreen ? '1.25rem' : isMediumScreen ? '1.5rem' : '1.75rem',
                            paddingLeft: isSmallScreen || isMediumScreen ? 4 : 10,
                            paddingRight: isSmallScreen || isMediumScreen ? 4 : 10,
                        }}
                    >
                        Monitoring
                    </Text>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 80,
                        left: 0,
                        zIndex: 3,
                        textAlign: 'left'
                    }}
                >
                    {textContents.map(({ variant, text }, index) => (
                        <Text
                            key={index}
                            variant={variant}
                            sx={{
                                color: 'white',
                                fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '16px',
                                marginBottom: 2,
                                paddingLeft: isSmallScreen || isMediumScreen ? 4 : 10,
                                paddingRight: isSmallScreen || isMediumScreen ? 4 : 10,
                            }}
                        >
                            {text}
                        </Text>
                    ))}
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: '#F0E8E3',
                    zIndex: 1,
                    top: -430,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0% 100%, 0% 60px)',
                    height: 200,
                    borderBottomRightRadius: 120,
                }}
            />
        </React.Fragment>
    );
}

export default LandingPageMonitoring;
