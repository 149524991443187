import { Theme } from '@mui/material/styles'

export const createStyles = (
    theme: Theme,
    isXSmallScreen: boolean,
    isSmallScreen: boolean,
    isMediumScreen: boolean,
    isLargeScreen: boolean
) => ({
    list: {
        width: isXSmallScreen
            ? '80vw'
            : isSmallScreen
                ? '300px'
                : isMediumScreen
                    ? '350px'
                    : isLargeScreen
                        ? '400px'
                        : '450px',
        bgcolor: 'background.paper',
        position: 'relative',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        '& ul': { padding: 0 },
        '&::-webkit-scrollbar': {
            width: '8px',
            cursor: 'pointer',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            cursor: 'pointer',
        },
    },
    listItemGroup: {
        color: '#123146',
        '& .MuiListItemText-primary': {
            fontSize: isXSmallScreen ? 12 : isSmallScreen ? 13 : 14,
            fontWeight: 600,
        },
    },
    listItemSubGroup: {
        pl: isXSmallScreen ? 4 : isSmallScreen ? 5 : 6,
        color: '#123146',
        '& .MuiListItemText-primary': {
            fontSize: isXSmallScreen ? 10 : 12,
            fontWeight: 400,
        },
    },
    listItemFeature: (higlightLayers: string[] | null, featureName: string) => ({
        pl: 8,
        bgcolor: higlightLayers?.includes(featureName) ? '#F0E8E3' : 'inherit',
        '&:hover': {
            bgcolor: '#F0E8E3',
        },
        cursor: 'pointer',
        '& .MuiListItemText-primary': {
            fontSize: 12,
            fontWeight: 400
        },
    }),
    iconDefault: {
        color: '#C4C4C4',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    iconVisible: {
        color: '#F65E27',
        cursor: 'pointer',
        ml: 1,
    },
    iconHidden: {
        color: '#C4C4C4',
        cursor: 'pointer',
        ml: 1,
    },
});
