import { useCallback } from "react";
import { useCheckAndRefreshToken } from "./useCheckAndRefreshToken";
import { useAuth } from "../context/auth-context/AuthContext";

export const useFetchWithAuth = () => {

  const checkAndRefreshToken = useCheckAndRefreshToken();
  const { baseUrl } = useAuth()

  const fetchWithAuth = useCallback(async (endpoint: string, options: RequestInit): Promise<Response> => {
    const token = await checkAndRefreshToken();

    if (!token) {
      throw new Error('No valid token available');
    }

    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };

    return fetch(baseUrl + endpoint, {
      ...options,
      headers,
    });
  }, [checkAndRefreshToken, baseUrl]);

  return fetchWithAuth;
};