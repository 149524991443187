import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import ButtonWithIcon from '../../common/button/Button';
import { DocumentFormValues, documentValidationSchema } from '../../../pages/document-page/DocumentViewLib';

const initialValues: DocumentFormValues = {
    file: null,
    newPath: '',
    fileName: '',
    tags: '',
};

const UploadContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 50,
    left: 0,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 'auto',
    backgroundColor: 'white',
    color: '#0B4566',
    padding: theme.spacing(2),
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
}));

const UploadDocument: React.FC<{ onClose: () => void }> = ({ onClose }) => {

    const { uploadDocument, path } = useDocumentView();
    const [newPath, setNewPath] = useState<string>(path.join('/'));
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        setNewPath(path.join('/'));
    }, [path]);

    const onSubmit = async (values: DocumentFormValues, actions: FormikHelpers<DocumentFormValues>) => {
        const trimmedNewPath = values.newPath.replace(/\/+$/, '');
        if (values.file) {
            uploadDocument(values.file, trimmedNewPath, values.fileName, values.tags);
            actions.setSubmitting(false);
            onClose();
        } else {
            alert('File is required');
            actions.setSubmitting(false);
        }
    };

    return (
        <UploadContainer>
            <Formik
                initialValues={{ ...initialValues, newPath }}
                validationSchema={documentValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue }) => (
                    <Form>
                        <Box sx={{ marginBottom: 2, textAlign: 'left' }}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={(event) => {
                                    const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                                    setFieldValue("file", file);
                                    if (file) {
                                        setFieldValue("fileName", file.name);
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2, textAlign: 'left' }}>
                            <Typography variant="caption">Current Path: {path.join('/')}</Typography>
                            <Field
                                component={TextField}
                                name="newPath"
                                label="Folder Name"
                                variant="filled"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2, textAlign: 'left' }}>
                            <Field
                                component={TextField}
                                name="fileName"
                                label="File Name"
                                variant="filled"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ marginBottom: 2, textAlign: 'left' }}>
                            <Field
                                component={TextField}
                                name="tags"
                                label="Tags"
                                variant="filled"
                                fullWidth
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <ButtonWithIcon
                                variant="contained"
                                type="button"
                                onClick={onClose}
                                icon={null}
                                text="Cancel"
                                sx={{
                                    backgroundColor: 'lightgray',
                                    borderRadius: 2,
                                    ':hover': {
                                        backgroundColor: 'lightgray',
                                        opacity: 0.8,
                                    },
                                }}
                            />
                            <ButtonWithIcon
                                variant="contained"
                                type="submit"
                                icon={null}
                                text="Upload"
                                sx={{
                                    backgroundColor: '#f58e3f',
                                    borderRadius: 2,
                                    ':hover': {
                                        backgroundColor: '#d47606',
                                        opacity: 0.8,
                                    },
                                    marginLeft: 2,
                                }}
                            />
                        </Box>
                    </Form>
                )}
            </Formik>
        </UploadContainer>
    );
};

export default UploadDocument;
