// src/components/DocumentListView/DirectoryRow.tsx
import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Folder as FolderIcon } from '@mui/icons-material';

interface DirectoryRowProps {
    subDir: any;
    level: number;
    path: string[];
    setPath: (path: string[]) => void;
}

const DirectoryRow: React.FC<DirectoryRowProps> = ({ subDir, level, path, setPath }) => (
    <TableRow sx={{ cursor: 'pointer' }} onClick={() => setPath([...path, subDir.name])}>
        <TableCell component="th" scope="row" style={{ paddingLeft: `10px` }}>
            <FolderIcon style={{ verticalAlign: 'bottom', marginRight: '5px' }} />
            {subDir.name}
        </TableCell>
        <TableCell align="right">{subDir.fileSize}</TableCell>
        <TableCell align="right">{subDir.modifiedDateTime}</TableCell>
        <TableCell align="right"></TableCell>
    </TableRow>
);

export default DirectoryRow;
