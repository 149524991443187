import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth } from '../../../context/auth-context/AuthContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getMenuBarData } from '../menu-bar/MenuData';
import { mobileMenuStyle, mobileNavLinkStyle, activeMobileNavLinkStyle, hoverMobileNavLinkStyle } from './Style';

const MobileMenu: React.FC = () => {

    const location = useLocation();
    const { user, setIsMobileMenuActive } = useAuth();
    const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

    const menuBarData = getMenuBarData(user);

    const hasAccess = (roles: string[]) => {
        const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];
        return userRoles.some((role: string) => roles.includes(role));
    };

    const accessibleMenus = user
        ? menuBarData.filter(item => hasAccess(item.roles) || item.alwaysAccessible)
        : menuBarData.filter(item => item.alwaysAccessible);

    return (
        <Box sx={() => mobileMenuStyle(isTabletOrDesktop, location)}>
            {accessibleMenus.map((res, index) => (
                <NavLink
                    key={res.id}
                    to={res.link}
                    onClick={() => setIsMobileMenuActive(false)}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{
                        ...mobileNavLinkStyle,
                        ...(location.pathname === res.link ? activeMobileNavLinkStyle : {}),
                        ...(hoveredIndex === index ? hoverMobileNavLinkStyle : {}),
                    }}
                >
                    {res.title}
                </NavLink>
            ))}
        </Box>
    );
};

export default MobileMenu;
