import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import DirectoryRow from './DirectoryRow';
import FileRow from './FileRow';
import { Directory } from '../../../pages/document-page/DocumentViewLib';
import { sortDocuments } from '../../../utils/DocumentUtils';

interface SubDirectoryRowProps {
    directories: Directory[];
    level: number;
    path: string[];
    setPath: (path: string[]) => void;
    sortColumn: string;
    sortDirection: 'asc' | 'desc';
    editing: boolean;
    editFileId: string | null;
    editFileName: string;
    setOpenDeleteConfirmation: (open: boolean) => void;
    setFileId: (id: string | null) => void;
    setEditing: (editing: boolean) => void;
    setEditFileId: (id: string | null) => void;
    setEditFileName: (name: string) => void;
}

const SubDirectoryRow: React.FC<SubDirectoryRowProps> = ({
    directories = [],
    level = 0,
    path,
    setPath,
    sortColumn,
    sortDirection,
    editing,
    editFileId,
    editFileName,
    setOpenDeleteConfirmation,
    setFileId,
    setEditing,
    setEditFileId,
    setEditFileName,
}) => {
    if (!directories.length) {
        return (
            <TableRow key="no-data">
                <TableCell colSpan={4} align="center">
                    No data available
                </TableCell>
            </TableRow>
        );
    }

    const renderedRows = directories.reduce<JSX.Element[]>((acc, directory) => {
        const hasSubDirectories = directory.subDirectories && directory.subDirectories.length;
        const hasFiles = directory.files && directory.files.length;

        const { sortedDirectories, sortedFiles } = sortDocuments(directory.subDirectories || [], directory.files || [], sortColumn, sortDirection);

        if (hasSubDirectories) {
            sortedDirectories.forEach((subDir, index) => {
                acc.push(
                    <DirectoryRow
                        key={`${level}-dir-${index}`}
                        subDir={subDir}
                        level={level}
                        path={path}
                        setPath={setPath}
                    />
                );
            });

            acc.push(
                <SubDirectoryRow
                    key={`${level}-sub-${Math.random()}`}
                    directories={sortedDirectories}
                    level={level + 1}
                    path={path}
                    setPath={setPath}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    editing={editing}
                    editFileId={editFileId}
                    editFileName={editFileName}
                    setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                    setFileId={setFileId}
                    setEditing={setEditing}
                    setEditFileId={setEditFileId}
                    setEditFileName={setEditFileName}
                />
            );
        }

        if (hasFiles) {
            sortedFiles.forEach((file, index) => {
                acc.push(
                    <FileRow
                        key={`${level}-file-${index}`}
                        file={file}
                        editing={editing}
                        editFileId={editFileId}
                        editFileName={editFileName}
                        setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                        setFileId={setFileId}
                        setEditing={setEditing}
                        setEditFileId={setEditFileId}
                        setEditFileName={setEditFileName}
                    />
                );
            });
        }

        return acc;
    }, []);

    return <React.Fragment>{renderedRows}</React.Fragment>;
};

export default SubDirectoryRow;
