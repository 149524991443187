import React from 'react'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { Box, Tooltip, IconButton, useTheme, useMediaQuery } from '@mui/material'
import { useMapView } from '../../../context/map-context/mapContext'
import { calculateLeftPosition } from '../../../utils/MapUtils'

const MapMenuButton = () => {
    const { showMenu, setShowMenu, mapRef } = useMapView()
    const theme = useTheme()
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    
    React.useEffect(() => {
        if (mapRef.current) {
            mapRef.current?.getMap().resize();
        }
    }, [showMenu, mapRef]);

    const screenSize = isXSmallScreen
    ? 'xs'
    : isSmallScreen
      ? 'sm'
      : isMediumScreen
        ? 'md'
        : isLargeScreen
          ? 'lg'
          : 'xl'

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: calculateLeftPosition(showMenu, screenSize),
                backgroundColor: 'white',
                width: 40,
                height: 50,
                zIndex: 3,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Tooltip title={showMenu ? "Hide menu" : "Show menu"}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ color: '#123146', fontSize: 14 }}
                    onClick={() => setShowMenu(!showMenu)}
                >
                    {showMenu ? <ArrowBackIosNew /> : <ArrowForwardIos />}
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default MapMenuButton
