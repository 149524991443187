import React from 'react';
import { Box, Tooltip, useMediaQuery } from '@mui/material';
import { ZoomInOutlined, ZoomOutOutlined, CenterFocusWeak, Fullscreen, FullscreenExit } from '@mui/icons-material';
import BasemapButton from '../../feature/basemap-button/BasemapButton';

interface NavigationControlsProps {
    handleInitialExtent: () => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
    onFullscreen: () => void;
    isFullScreen: boolean;
}

const NavigationControls: React.FC<NavigationControlsProps> = ({
    handleInitialExtent,
    onZoomIn,
    onZoomOut,
    onFullscreen,
    isFullScreen
}) => {

    const FullscreenIcon = isFullScreen ? FullscreenExit : Fullscreen;
    const isSmallScreen = useMediaQuery('(min-width: 999px)')

    const buttons = [
        {
            title: "Default Extent",
            icon: <CenterFocusWeak onClick={handleInitialExtent} />,
        },
        {
            title: "Zoom In",
            icon: <ZoomInOutlined onClick={onZoomIn} />,
        },
        {
            title: "Zoom Out",
            icon: <ZoomOutOutlined onClick={onZoomOut} />,
        },
        {
            title: isFullScreen ? "Exit Full Extend" : "Full Extend",
            icon: <FullscreenIcon onClick={onFullscreen} />,
        }
    ];

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: 1
            }}
        >
            {buttons.map((button, index) => (
                <Tooltip key={index} title={button.title}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        padding: isSmallScreen ? 1 : '1px',
                        cursor: 'pointer',
                        position: 'relative',
                        boxSizing: 'border-box',
                        '&:hover': {
                            opacity: 0.8,
                        }
                    }}>
                        {button.icon}
                    </Box>
                </Tooltip>
            ))}
            <BasemapButton />
        </Box>
    );
}

export default NavigationControls;
