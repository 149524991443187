import React from 'react';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { handleSort, formatDate, formatDigitValue, SortConfig } from '../../../utils/Utils';
import { getSafeImage } from '../../../utils/MapUtils';
import { detailStyle, parametersStyle, parameterItemStyle, parameterTextStyle } from './Style';
import Text from '../../common/text/Text';
import { useAuth } from '../../../context/auth-context/AuthContext';

interface PopupParameterProps {
    selectedStation: any;
    sortedParameters: any[];
    sortConfig: SortConfig;
    setSortConfig: React.Dispatch<React.SetStateAction<SortConfig>>;
}

const PopupParameter: React.FC<PopupParameterProps> = ({ selectedStation, sortedParameters, sortConfig, setSortConfig }) => {
    const { user } = useAuth();

    const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];

    const arrangeParameters = (parameters: any[]) => {
        if (selectedStation?.dataType !== 'WaterQuality') return parameters;

        const firstRow = ['Chlorophyll-A', 'DO', 'Salinity', 'Temperature'];
        const secondRow = ['pH', 'Conductivity', 'Turbidity'];

        if (userRoles.includes('DHIAdmin') || userRoles.includes('SuperAdministrator')) {
            secondRow.push('Battery');
        }

        const firstRowParams = parameters.filter(param => firstRow.includes(param.name));
        const secondRowParams = parameters.filter(param => secondRow.includes(param.name)).reverse();

        return [...firstRowParams, ...secondRowParams];
    };

    const modifyParametersForSedimentQuality = (parameters: any[]) => {
        return parameters.map((param, index) => {
            if (index === 0) {
                return { ...param, name: 'Temperature' };
            }
            if (index === 1) {
                return { ...param, name: 'ORP' };
            }
            return param;
        });
    };

    const arrangedParameters = selectedStation?.dataType === 'SedimentQuality'
        ? modifyParametersForSedimentQuality(sortedParameters)
        : arrangeParameters(sortedParameters);

    return (
        <Box sx={{ padding: 1, width: '100%' }}>
            {selectedStation?.dataType === 'PlanktonSurvey' ? (
                <TableContainer component={Paper} sx={{ maxHeight: 200, overflow: 'auto', width: '100%' }}>
                    <Table sx={{ width: '100%' }} aria-label="parameter table" stickyHeader>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell sx={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('name', sortConfig, setSortConfig)}>
                                    Species
                                    {sortConfig.key === 'name' ? (sortConfig.direction === 'ascending' ? <ArrowDropUp sx={{ ml: '2px', fontSize: '16px' }} /> : <ArrowDropDown sx={{ ml: '2px', fontSize: '16px' }} />) : ''}
                                </TableCell>
                                <TableCell align="right" sx={{ fontSize: '13px', fontWeight: 'bold' }}>Value (cell/mL)</TableCell>
                                <TableCell sx={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('speciesType', sortConfig, setSortConfig)}>
                                    Type
                                    {sortConfig.key === 'speciesType' ? (sortConfig.direction === 'ascending' ? <ArrowDropUp sx={{ ml: '2px', fontSize: '16px' }} /> : <ArrowDropDown sx={{ ml: '2px', fontSize: '16px' }} />) : ''}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedParameters.length > 0 ? (
                                sortedParameters?.map((param: any) => (
                                    <TableRow key={param.name}>
                                        <TableCell component="th" scope="row" sx={{ fontSize: '12px' }}>
                                            {param.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{ fontSize: '12px', color: param.isWithinThresholdLimits ? 'black' : 'red' }}>{formatDigitValue(param.latestValue, selectedStation?.dataType)}</TableCell>
                                        <TableCell sx={{ fontSize: '12px' }}>{param?.speciesType}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow >
                                    <TableCell colSpan={4} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                arrangedParameters.length > 0 ? (
                    <Box sx={detailStyle}>
                        <Text variant="caption" sx={{ color: '#86A2B3' }}>Parameters:</Text>
                        <Box sx={parametersStyle}>
                            {arrangedParameters.map((param: any, index: number) => (
                                <Box key={index} sx={parameterItemStyle}>
                                    {selectedStation?.dataType !== 'SedimentQuality' && (
                                        <Box sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 1 }}>
                                            <img
                                                src={getSafeImage(param.isOnline ? (param.isWithinThresholdLimits ? 'Online' : 'Warning') : param.isWithinThresholdLimits ? 'Offline' : 'Warning', param.name)}
                                                alt={`${param.name} icon`}
                                                style={{ height: 25 }}
                                            />
                                        </Box>
                                    )}
                                    <Box sx={parameterTextStyle}>
                                        <Text
                                            variant="caption"
                                            sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                        >
                                            {param.name}
                                        </Text>
                                        <Text variant="caption" sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}>
                                            {formatDigitValue(param.latestValue, selectedStation.dataType)} {param.unit}
                                        </Text>
                                        <Text
                                            variant="caption"
                                            sx={{ color: '#9B9B9B', fontSize: 10, textAlign: 'left' }}
                                        >
                                            {formatDate(param.lastUpdatedDateTime)}
                                        </Text>
                                    </Box>
                                </Box>
                            ))}
                            {selectedStation?.dataType === 'SedimentQuality' && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 2 }}>
                                    <Box sx={parameterItemStyle}>
                                        <Box sx={parameterTextStyle}>
                                            <Text
                                                variant="caption"
                                                sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                            >
                                                Sulphure Odour:
                                            </Text>
                                            <Text variant="caption" sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}>
                                                {selectedStation?.sulphureOdour ?? ' - '}
                                            </Text>
                                            <Text
                                                variant="caption"
                                                sx={{ color: '#9B9B9B', fontSize: 10, textAlign: 'left' }}
                                            >
                                                {formatDate(selectedStation?.samplingDateTime)}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box sx={parameterItemStyle}>
                                        <Box sx={parameterTextStyle}>
                                            <Text
                                                variant="caption"
                                                sx={{ color: '#9B9B9B', fontSize: 11, fontWeight: 'bold', textAlign: 'left' }}
                                            >
                                                Sediment Type:
                                            </Text>
                                            <Text variant="caption" sx={{ color: '#09334B', fontSize: 12, fontWeight: 'bold' }}>
                                                {selectedStation?.sedimentType ?? ' - '}
                                            </Text>
                                            <Text
                                                variant="caption"
                                                sx={{ color: '#9B9B9B', fontSize: 10, textAlign: 'left' }}
                                            >
                                                {formatDate(selectedStation?.samplingDateTime)}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box>No data available</Box>
                )
            )}
        </Box>
    );
};

export default PopupParameter;
