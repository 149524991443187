export const containerStyle = {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    zIndex: 2,
    backgroundColor: 'white',
    minHeight: 500,
    overFlow: 'auto'
};