export const mobileMenuStyle = (isMobile: boolean, location: any) => ({
    display: location.pathname === '/login' && isMobile ? 'none' : 'flex',
    flex: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: '65px',
    left: 0,
    zIndex: 20,
    backgroundColor: 'white',
    padding: '20px 0',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
});

export const mobileNavLinkStyle = {
    display: 'inline-block',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'none',
    color: 'black',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s, color 0.3s',
};

export const activeMobileNavLinkStyle = {
    fontWeight: 700,
    color: '#F65E27',
    backgroundColor: '#F0E8E3',
};

export const hoverMobileNavLinkStyle = {
    backgroundColor: '#F0E8E3',
    color: '#F65E27',
};
