import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOidc } from '@axa-fr/react-oidc';

function SilentRenewCallback() {
    const { renewTokens } = useOidc();
    const navigate = useNavigate();

    useEffect(() => {
        renewTokens()
            .then(() => {
                navigate('/map-view', { replace: true });
            })
            .catch((error) => {
                console.error('Silent renew error:', error);
                navigate('/login', { replace: true });
            });
    }, [renewTokens, navigate]);

    return <div>Processing silent renew...</div>;
}

export default SilentRenewCallback;
