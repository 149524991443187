import React from 'react';
import { Marker } from 'react-map-gl';
import { Popover, List, ListItem, ListItemText } from '@mui/material';
import { iconStyle, textStyle } from './Style';
import { useMapView } from '../../../context/map-context/mapContext';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { getIcon, getIconStyle, handleHighlightLayer } from '../../../utils/MapUtils';

interface Station {
    stationId: string;
    latitude: number;
    longitude: number;
    dataType: string;
    isOnline: boolean;
    isWithinThresholdLimits: boolean;
}

interface MapMarkerProps {
    stations: Station[];
}

const MapMarker: React.FC<MapMarkerProps> = ({ stations }) => {

    const { fetchStationById, fetchStationsByDateAndId, isShowTimeSlider, popupInfo, setPopupInfo, highlightLayer, setHighlightLayer } = useMapView();
    const { stationInfo, setStationInfo } = useTimeseriesView();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openPopover, setOpenPopover] = React.useState(false);

    const handleMarkerMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        if (stations.length > 1 && !popupInfo) {
            setAnchorEl(event.currentTarget);
            setOpenPopover(true);
        }
    };

    const handleStationClick = (station: Station) => {
        handlePopoverClose();

        setStationInfo({
            ...stationInfo,
            selectedDataType: station.dataType,
            selectedArea: '',
            selectedStation: [station.stationId],
            selectedParameters: [],
        });

        if (station.dataType !== 'WaterQuality' && isShowTimeSlider) {
            fetchStationsByDateAndId(station.stationId)
        } else {
            fetchStationById(station.stationId);
        }

        handleHighlightLayer([station.stationId], setHighlightLayer, false);
        if (popupInfo) {
            setPopupInfo(null);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenPopover(false);
    };

    return (
        <React.Fragment>
            {stations.length > 0 && stations.map((station: Station, i) => (
                <Marker key={`${station.stationId}-${i}`} latitude={station.latitude} longitude={station.longitude}>
                    <div style={getIconStyle(station.isOnline, station.isWithinThresholdLimits, highlightLayer.includes(station.stationId))}>
                        <img
                            src={getIcon(station.dataType)}
                            alt={`${station.stationId}`}
                            style={iconStyle}
                            onMouseEnter={handleMarkerMouseEnter}
                            onClick={() => handleStationClick(station)}
                        />
                    </div>
                    <p style={textStyle}>{station.stationId}</p>
                </Marker>
            ))}
            {stations.length > 1 && (
                <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        {stations.map((station: Station) => (
                            <ListItem button key={station.stationId} onClick={() => {
                                handleStationClick(station);
                                handleHighlightLayer([station.stationId], setHighlightLayer, false);
                            }}>
                                <ListItemText primary={station.stationId} />
                            </ListItem>
                        ))}
                    </List>
                </Popover>
            )}
        </React.Fragment>
    );
};

export default MapMarker;