// components/DocumentSkeleton.tsx
import React from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';

const DocumentSkeleton: React.FC = () => {
    return (
        <React.Fragment>
            {Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                    <TableCell>
                        <Skeleton variant="text" width="100%" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" width="100%" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" width="100%" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" width="100%" />
                    </TableCell>
                </TableRow>
            ))}
        </React.Fragment>
    );
};

export default DocumentSkeleton;
