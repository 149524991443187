// import { Person, PeopleAlt, HelpOutline, VerifiedUser, Security, Logout } from '@mui/icons-material';
import { HelpOutline, VerifiedUser, Security, Logout } from '@mui/icons-material';
import { useAuth } from '../../../context/auth-context/AuthContext';

const useUserSettings = () => {
    // const { user, logout, setShowDisclaimer, showDisclaimer } = useAuth();
    const { logout, setShowDisclaimer, showDisclaimer } = useAuth();

    // const hasRole = (role: string) => {
    //     return Array.isArray(user?.roles) ? user.roles.includes(role) : user?.roles === role;
    // };

    return [
        // {
        //     title: 'Edit Profile',
        //     icon: Person,
        //     onclick: () => console.log('edit profile'),
        //     isAuthorized: true,
        // },
        // {
        //     title: 'Manage User',
        //     icon: PeopleAlt,
        //     onclick: () => console.log('manage user'),
        //     isAuthorized: hasRole('Administrator') || hasRole('DHIAdmin') || hasRole('SuperAdministrator'),
        // },
        {
            title: 'Support',
            icon: HelpOutline,
            onclick: () => console.log('support'),
            isAuthorized: true,
        },
        {
            title: 'Disclaimer',
            icon: VerifiedUser,
            onclick: () => setShowDisclaimer(!showDisclaimer),
            isAuthorized: true,
        },
        {
            title: 'Privacy',
            icon: Security,
            onclick: () => window.open('https://www.dhigroup.com/legal-and-compliance/privacy-policy', '_blank'),
            isAuthorized: true,
        },
        {
            title: 'Logout',
            icon: Logout,
            onclick: () => {
                logout();
                window.location.reload();
            },
            isAuthorized: true,
        },
    ];
};

export default useUserSettings;
