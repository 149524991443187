import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { useMapView } from '../../../context/map-context/mapContext';

const FishFarmLayer = ({ fishFarm, hoveredFeatureName }: { fishFarm: any, hoveredFeatureName: string | null }) => {
    const { mapRef } = useMapView();

    React.useEffect(() => {
        const map = mapRef.current;
        if (!map) return;

        const handleLayerClick = (event: any) => {
            const features = event.features;
            if (features && features.length > 0) {
                const feature = features[0];
                const coordinates = feature.geometry.coordinates[0][0];
                const [longitude, latitude] = coordinates;

                map.flyTo({
                    center: [longitude, latitude],
                    zoom: 15,
                    essential: true,
                    duration: 1500
                });
            }
        };

        map.on('click', 'fishFarmFillLayer', handleLayerClick);
        map.on('click', 'fishFarmOutlineLayer', handleLayerClick);
        map.on('click', 'fishFarmNameLayer', handleLayerClick);

        return () => {
            map.off('click', 'fishFarmFillLayer', handleLayerClick);
            map.off('click', 'fishFarmOutlineLayer', handleLayerClick);
            map.off('click', 'fishFarmNameLayer', handleLayerClick);
        };
    }, [mapRef]);

    return (
        <Source id="fishFarmSource" type="geojson" data={fishFarm}>
            <Layer
                id="fishFarmFillLayer"
                type="fill"
                paint={{
                    'fill-color': '#5c8ab9',
                    'fill-opacity': ['case', ['==', ['get', 'Name'], hoveredFeatureName], 0.5, 0.4],
                }}
                filter={['==', '$type', 'Polygon']}
            />
            <Layer
                id="fishFarmOutlineLayer"
                type="line"
                paint={{
                    'line-width': ['case', ['==', ['get', 'Name'], hoveredFeatureName], 5, 2],
                    'line-color': '#5c8ab9',
                }}
                filter={['==', '$type', 'Polygon']}
            />
            <Layer
                id="fishFarmNameLayer"
                type="symbol"
                layout={{
                    'text-field': ['get', 'Name'],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                }}
                paint={{
                    'text-color': ['case', ['==', ['get', 'Name'], hoveredFeatureName], '#000', 'transparent'],
                    'text-halo-color': ['case', ['==', ['get', 'Name'], hoveredFeatureName], '#fff', 'transparent'],
                    'text-halo-width': 1,
                }}
                filter={['==', '$type', 'Polygon']}
            />
        </Source>
    );
};

export default FishFarmLayer;
