import React, { useState } from 'react';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import DeleteConfirmation from './DeleteConfirmation';
import { handleSortChange } from '../../../utils/DocumentUtils';
import DocumentSkeleton from './DocumentSkeleton';
import SubDirectoryRow from './SubDirectoryRow';

const DocumentListView: React.FC = () => {
    const { documentList, loading, path, setPath } = useDocumentView();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
    const [fileId, setFileId] = useState<string | null>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [editFileId, setEditFileId] = useState<string | null>(null);
    const [editFileName, setEditFileName] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={sortColumn === 'name'}
                                    direction={sortColumn === 'name' ? sortDirection : 'asc'}
                                    onClick={() => handleSortChange('name', sortColumn, sortDirection, setSortColumn, setSortDirection)}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                Size
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                <TableSortLabel
                                    active={sortColumn === 'modifiedDateTime'}
                                    direction={sortColumn === 'modifiedDateTime' ? sortDirection : 'asc'}
                                    onClick={() => handleSortChange('modifiedDateTime', sortColumn, sortDirection, setSortColumn, setSortDirection)}
                                >
                                    Modified
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <DocumentSkeleton /> : (
                            <SubDirectoryRow
                                directories={documentList?.directories || []}
                                level={0}
                                path={path}
                                setPath={setPath}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                editing={editing}
                                editFileId={editFileId}
                                editFileName={editFileName}
                                setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                                setFileId={setFileId}
                                setEditing={setEditing}
                                setEditFileId={setEditFileId}
                                setEditFileName={setEditFileName}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteConfirmation
                fileId={fileId}
                openDialog={openDeleteConfirmation}
                setOpenDialog={setOpenDeleteConfirmation}
            />
        </React.Fragment>
    );
};

export default DocumentListView;
