import React from 'react';
import { Select, MenuItem, TextField } from '@mui/material';

interface InputFieldRendererProps {
  field: string | number | Date;
  filterValue: string | number | Date;
  setFilterValue: (value: string | number | Date) => void;
  filterLabel: string | null;
}

const InputFieldRenderer: React.FC<InputFieldRendererProps> = ({
  field,
  filterValue,
  setFilterValue,
  filterLabel,
}) => {
  switch (field) {
    case 'type':
      return (
        <Select
          fullWidth
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value as string)}
        >
          <MenuItem value="">- Select All -</MenuItem>
          <MenuItem value="0">Sensors</MenuItem>
          <MenuItem value="1">Samples</MenuItem>
          <MenuItem value="2">Others</MenuItem>
        </Select>
      );
    case 'status':
      return (
        <Select
          fullWidth
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value as string)}
        >
          <MenuItem value="">- Select All -</MenuItem>
          <MenuItem value="0">New</MenuItem>
          <MenuItem value="1">In Progress</MenuItem>
          <MenuItem value="2">Completed</MenuItem>
          <MenuItem value="3">On Hold</MenuItem>
        </Select>
      );
    default:
      return (
        <TextField
          label={`Enter ${filterLabel}`}
          variant="outlined"
          fullWidth
          type={
            ['startedDateTime', 'completedDateTime', 'reportedDateTime'].includes(
              field as any
            )
              ? 'date'
              : 'text'
          }
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value as string | Date | number)}
          sx={{ marginTop: 1, fontSize: 11 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
  }
};

export default InputFieldRenderer;
