import { format } from 'date-fns';

export type Status = {
    label: string;
    name: string;
    value: number
}

export const eventLabel = [
    {
        label: 'Event ID',
        value: 'id',
        editable: false,
    },
    {
        label: 'Type',
        value: 'type',
        inputType: 'select',
        formatter: (value: any) => ['Sensors', 'Samples', 'Others'][value],
    },
    {
        label: 'Description',
        value: 'description',
        inputType: 'text',
    },
    {
        label: 'Start',
        value: 'startedDateTime',
        inputType: 'date',
        formatter: (value: string | number | Date) => value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : '',
    },
    {
        label: 'End',
        value: 'completedDateTime',
        inputType: 'date',
        formatter: (value: string | number | Date) => value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : '',
    },
    {
        label: 'Created by',
        value: 'createdBy',
        inputType: 'text',
    },
    {
        label: 'Created date',
        value: 'reportedDateTime',
        inputType: 'date',
        formatter: (value: string | number | Date) => value ? format(new Date(value), 'yyyy-MM-dd') : '',
    },
    {
        label: 'Status',
        value: 'status',
        inputType: 'select',
        formatter: (value: any) => ['New', 'In Progress', 'Completed', 'On Hold'][value],
    },
    {
        label: 'Assigned to',
        value: 'assignedTo',
        inputType: 'select',
    },
    {
        label: 'Remarks',
        value: 'remarks',
        inputType: 'text',
    },
]