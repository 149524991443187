// useSendMessage.ts
import { FormikHelpers } from 'formik';
import { SendMessageFormValues } from '../pages/contact-us-page/ContactUsLib';
import { useContact } from '../context/contact-context/contactContext';

const useSendMessage = () => {
  
  const { sendEmail } = useContact();
  
  const onSubmit = async (
    values: SendMessageFormValues,
    actions: FormikHelpers<SendMessageFormValues>
  ) => {
    try {
      await sendEmail(values);
      actions.setStatus({ success: 'Message sent successfully!' });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      actions.setStatus({ error: 'Failed to send message. Please try again later.' });
      actions.setSubmitting(false);
    }
  };

  return { onSubmit };
};

export default useSendMessage;
