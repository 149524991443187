import React from 'react'
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import LayerFilter from '../../feature/filter/LayerFilter'
import { useMapView } from '../../../context/map-context/mapContext'
import LayerList from './LayerList'

const MapMenu = () => {
  const { showMenu, setShowMenu } = useMapView()
  const theme = useTheme()
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  React.useEffect(() => {
    if (isXSmallScreen || isSmallScreen) {
      setShowMenu(false)
    }
  }, [isXSmallScreen, isSmallScreen, setShowMenu])

  const getWidth = (): number | string => {
    switch (true) {
      case isXSmallScreen:
        return '80vw'
      case isSmallScreen:
        return 300
      case isMediumScreen:
        return 350
      case isLargeScreen:
        return 400
      default:
        return 450
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        top: 0,
        left: showMenu ? 0 : -450,
        width: showMenu ? getWidth() : 0,
        height: 'calc(100vh - 60px)',
        boxSizing: 'border-box',
        zIndex: 2,
        backgroundColor: 'white',
      }}
    >
      <LayerFilter />
      <Divider />
      <LayerList />
    </Box>
  )
}

export default MapMenu
