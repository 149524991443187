import React from 'react'
import { Box, Stack } from '@mui/material';
import { footerStyle } from './Style';
import Text from '../../common/text/Text';

interface popupFooterProps {
    onClick: () => void;
    icon: string;
    label: string
}

const PopupFooter: React.FC<popupFooterProps> = ({ onClick, icon, label }) => {
    return (
        <Box sx={footerStyle}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }} onClick={onClick} className="no-drag">
                <img src={icon} alt="link-icon" />
                <Text variant="caption" sx={{ color: '#0B4566' }}>{label}</Text>
            </Stack>
        </Box>
    )
}

export default PopupFooter