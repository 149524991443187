import React from 'react'
import Typography from '@mui/material/Typography';

interface TextProps {
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit";
    children: React.ReactNode;
    [key: string]: any;
}

const Text: React.FC<TextProps> = ({ variant, children, ...props }) => {
    return (
        <Typography variant={variant} {...props}>
            {children}
        </Typography>
    );
}

export default Text;