import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Pagination, PaginationItem, Radio, FormControlLabel, IconButton } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';
import { useMapView } from '../../../context/map-context/mapContext';

const TimeSlider: React.FC = () => {
  const { dataType, dataTypeLabel, availableDates, setSelectedDate, fetchStationsByDate } = useMapView();

  const [currentDateIndex, setCurrentDateIndex] = React.useState<number | null>(null);
  const [isAutoplaying, setIsAutoplaying] = React.useState<boolean>(false);
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (availableDates.length > 0) {
      const firstDate = availableDates[0];
      setCurrentDateIndex(0);
      setSelectedDate(firstDate);
      fetchStationsByDate(dataType, firstDate);
    }
  }, [availableDates]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const newIndex = page - 1;
    const selectedDate = availableDates[newIndex];
    setCurrentDateIndex(newIndex);
    setSelectedDate(selectedDate);
    fetchStationsByDate(dataType, selectedDate);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, page: number) => {
    const newIndex = page - 1;
    const selectedDate = availableDates[newIndex];
    setCurrentDateIndex(newIndex);
    setSelectedDate(selectedDate);
    fetchStationsByDate(dataType, selectedDate);
  };

  const startAutoplay = () => {
    if (!isAutoplaying) {
      const id = setInterval(() => {
        setCurrentDateIndex((prevIndex) => {
          const nextIndex = prevIndex !== null ? (prevIndex + 1) % availableDates.length : 0;
          const selectedDate = availableDates[nextIndex];
          setSelectedDate(selectedDate);
          fetchStationsByDate(dataType, selectedDate);
          return nextIndex;
        });
      }, 5000);
      setIntervalId(id);
      setIsAutoplaying(true);
    }
  };

  const stopAutoplay = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      setIsAutoplaying(false);
    }
  };

  const toggleAutoplay = () => {
    if (isAutoplaying) {
      stopAutoplay();
    } else {
      startAutoplay();
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'absolute', zIndex: 2, bottom: 5, left: 0, boxSizing: 'border-box', backgroundColor: 'white' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          width: '100%',
          backgroundColor: '#DFE7EB',
          padding: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} color={'#86A2B3'}>
          <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
            Time Slider {dataTypeLabel}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} color={'#86A2B3'}>
          <IconButton onClick={toggleAutoplay}>
            {isAutoplaying ? <Stop /> : <PlayArrow />}
          </IconButton>
          <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
            Autoplay
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: '10px' }}>
        <Pagination
          count={availableDates.length}
          page={currentDateIndex !== null ? currentDateIndex + 1 : 1}
          onChange={handlePaginationChange}
          siblingCount={1}
          boundaryCount={1}
          showFirstButton
          showLastButton
          renderItem={(item) => {
            const pageIndex = item.page !== null ? item.page - 1 : -1;
            const selectedDate = pageIndex >= 0 && availableDates[pageIndex];
            return (
              <PaginationItem
                {...item}
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#E0F7FA',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#E0F7FA',
                  },
                }}
                page={
                  item.page !== null && item.page > 0 && item.page <= availableDates.length ? (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={currentDateIndex === pageIndex}
                          onChange={(e) => handleRadioChange(e, item.page as number)}
                          value={availableDates[pageIndex]}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '10px' }}>
                          {selectedDate && new Date(selectedDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                        </Typography>
                      }
                      sx={{ margin: 0 }}
                    />
                  ) : (
                    item.page
                  )
                }
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default TimeSlider;
