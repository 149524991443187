import React from 'react';
import UploadWrapper from './UploadWrapper';
import { useUploadView } from '../../../context/upload-context/UploadContext';
import { additionalFileAccept, fileAccept } from './UploadMenu';

const SedimentQuality: React.FC = () => {
    const { loading, downloadTemplateSedimentQualityResult, uploadSedimentQualityResult, uploadAdditionalSedimentQualityResult } = useUploadView();

    const handleUploadFile = async (file: File): Promise<void> => {
        await uploadSedimentQualityResult(file);
    };

    const handleUploadAdditionalFiles = async (files: File[]): Promise<void> => {
        await uploadAdditionalSedimentQualityResult(files);
    };

    return (
        <UploadWrapper
            title="Sediment Quality Result Upload"
            templateDownload={downloadTemplateSedimentQualityResult}
            uploadFile={handleUploadFile}
            uploadAdditionalFile={handleUploadAdditionalFiles}
            fileAccept={fileAccept}
            additionalFileAccept={additionalFileAccept}
            anotherButtonText="Upload Another Sediment Quality Result"
            loading={loading}
        />
    );
}

export default SedimentQuality;
