import React from 'react';
import { Box, MenuItem, useMediaQuery } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import TelegramIcon from '@mui/icons-material/Telegram';
import ButtonWithIcon from '../../common/button/Button';
import { SendMessageFormValues } from '../../../pages/contact-us-page/ContactUsLib';

interface SendMessageFormProps {
    categories: string[];
    initialValues: SendMessageFormValues;
    validationSchema: any;
    onSubmit: (values: SendMessageFormValues, actions: FormikHelpers<SendMessageFormValues>) => Promise<void>;
    resetRecaptcha: () => void;
    reCaptChaSiteKey?: string;
}

const SendMessageForm: React.FC<SendMessageFormProps> = ({
    categories,
    initialValues,
    validationSchema,
    onSubmit,
    resetRecaptcha,
    reCaptChaSiteKey,
}) => {
    const isSmallScreen = useMediaQuery('(max-width: 999px)');

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ resetForm }) => (
                <Form style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            flexWrap: 'wrap',
                            gap: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Field
                            component={TextField}
                            name="name"
                            label="Name"
                            variant="filled"
                            fullWidth
                            sx={{ flex: 1 }}
                            InputProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                            InputLabelProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                        />
                        <Field
                            component={TextField}
                            name="email"
                            label="Email"
                            variant="filled"
                            fullWidth
                            sx={{ flex: 1 }}
                            InputProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                            InputLabelProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            flexWrap: 'wrap',
                            gap: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Field
                            component={TextField}
                            name="subject"
                            label="Subject"
                            variant="filled"
                            fullWidth
                            sx={{ flex: 1 }}
                            InputProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                            InputLabelProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                        />
                        <Field
                            component={TextField}
                            type="text"
                            name="category"
                            label="Category"
                            select
                            variant="filled"
                            fullWidth
                            sx={{ flex: 1 }}
                            InputProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px', textAlign: 'left' },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                        >
                            {categories?.map((option: string) => (
                                <MenuItem key={option} value={option} sx={{ fontSize: isSmallScreen ? '12px' : '14px' }}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Field>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            gap: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Field
                            component={TextField}
                            name="enquiry"
                            label="Enquiry"
                            multiline
                            rows={4}
                            variant="filled"
                            fullWidth
                            sx={{ flex: 1 }}
                            InputProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                            InputLabelProps={{
                                style: { fontSize: isSmallScreen ? '12px' : '14px' },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                            marginBottom: 2,
                        }}
                    >
                        <div className="g-recaptcha" data-sitekey={reCaptChaSiteKey}></div>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                            marginTop: 1,
                        }}
                    >
                        <ButtonWithIcon
                            variant='contained'
                            type='button'
                            icon={null}
                            onClick={() => {
                                resetForm();
                                resetRecaptcha();
                            }}
                            text='Clear'
                            sx={{
                                backgroundColor: 'lightgray',
                                borderRadius: 2,
                                ':hover': {
                                    backgroundColor: 'lightgray',
                                    opacity: 0.8,
                                },
                            }}
                        />
                        <ButtonWithIcon
                            variant='contained'
                            type='submit'
                            icon={<TelegramIcon />}
                            onClick={() => { }}
                            text='Send'
                            sx={{
                                backgroundColor: '#f58e3f',
                                borderRadius: 2,
                                ':hover': {
                                    backgroundColor: '#d47606',
                                    opacity: 0.8,
                                },
                                marginLeft: 2,
                            }}
                        />
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default SendMessageForm;
