import { File, Directory } from "../pages/document-page/DocumentViewLib";

//  sort document
export const sortDocuments = (directories: Directory[], files: File[] = [], sortColumn: string, sortDirection: 'asc' | 'desc') => {
    const isAsc = sortDirection === 'asc';

    const sortedDirectories = [...directories].sort((a, b) => {
        if (sortColumn === 'name') {
            return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (sortColumn === 'modifiedDateTime') {
            return isAsc
                ? new Date(a.modifiedDateTime).getTime() - new Date(b.modifiedDateTime).getTime()
                : new Date(b.modifiedDateTime).getTime() - new Date(a.modifiedDateTime).getTime();
        }
        return 0;
    });

    const sortedFiles = [...files].sort((a, b) => {
        if (sortColumn === 'name') {
            return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (sortColumn === 'modifiedDateTime') {
            return isAsc
                ? new Date(a.modifiedDateTime).getTime() - new Date(b.modifiedDateTime).getTime()
                : new Date(b.modifiedDateTime).getTime() - new Date(a.modifiedDateTime).getTime();
        }
        return 0;
    });

    return { sortedDirectories, sortedFiles };
};

//  handle sort document
export const handleSortChange = (column: string, currentSortColumn: string, currentSortDirection: 'asc' | 'desc', setSortColumn: (column: string) => void, setSortDirection: (direction: 'asc' | 'desc') => void) => {
    const isAsc = currentSortColumn === column && currentSortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
};

// find file name
export const findFileNameById = (documentList: any, fileId: string): string | null => {
    const traverseDirectories = (directories: any[]): string | null => {
        for (const directory of directories) {
            if (directory.files) {
                for (const file of directory.files) {
                    if (file.id === fileId) {
                        return file.name;
                    }
                }
            }
            if (directory.subDirectories) {
                const foundInSubDirectory = traverseDirectories(directory.subDirectories);
                if (foundInSubDirectory) {
                    return foundInSubDirectory;
                }
            }
        }
        return null;
    };

    return traverseDirectories(documentList.directories);
};

// handle edit click
export const handleEditClick = (file: File, setEditing: (val: boolean) => void, setEditFileId: (val: string) => void, setEditFileName: (val: string) => void) => {
    setEditing(true);
    setEditFileId(file.id);
    setEditFileName(file.name);
};

// handle save click
export const handleSaveClick = (editFileId: string, setEditing: (val: boolean) => void, setEditFileId: (val: string | null) => void, setEditFileName: (val: string) => void, onRename: () => void) => {
    if (editFileId) {
        onRename()
    }
    setEditing(false);
    setEditFileId(null);
    setEditFileName('');
};

// handle cancel click
export const handleCancelClick = (setEditing: (val: boolean) => void, setEditFileId: (val: string | null) => void, setEditFileName: (val: string) => void) => {
    setEditing(false);
    setEditFileId(null);
    setEditFileName('');
};

// handle click clear button
export const handleClickClearButton = (id: string, setOpenDeleteConfirmation: (val: boolean) => void, setFileId: (val: string) => void) => {
    setOpenDeleteConfirmation(true);
    setFileId(id);
};