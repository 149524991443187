import React from 'react'
import Box from '@mui/material/Box';
import UserProfile from '../../feature/user-profile/UserProfile';
import AppLogo from '../../feature/app-logo/AppLogo';
import MenuBar from '../../feature/menu-bar/MenuBar';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { Tooltip, useMediaQuery } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import MobileMenu from '../../feature/mobile-menu/MobileMenu';

const Header = () => {
    
    const location = useLocation()
    const { user, isMobileMenuActive, setIsMobileMenuActive } = useAuth()
    const isTabletOrDesktop = useMediaQuery('(min-width: 1300px)');

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'relative',
                    height: '65px',
                    top: 0,
                    left: 0,
                    display: location.pathname === '/login' ? 'none' : 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: 2,
                    paddingLeft: 2,
                    backgroundColor: location.pathname === '/' ? 'transparent' : '#eef2f5',
                    zIndex: 1
                }}
            >
                <AppLogo />
                <MenuBar />
                <UserProfile />
                {
                    user !== null && !isTabletOrDesktop && (
                        <Tooltip title="menu">
                            <AppsIcon
                                sx={{
                                    cursor: 'pointer',
                                    color: location.pathname === '/' ? 'white' : 'black'
                                }}
                                onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
                            />
                        </Tooltip>
                    )
                }
            </Box >
            {isMobileMenuActive && !isTabletOrDesktop && location.pathname !== '/login' && <MobileMenu />}
        </React.Fragment>
    )
}

export default Header