import * as Yup from 'yup';

export interface DocumentFormValues {
    fileName: string;
    newPath: string;
    tags: string;
    file: File | null;
}

export const documentValidationSchema = Yup.object({
    file: Yup.mixed().nullable().required('A file is required'),
    newPath: Yup.string().required('New Folder name is required'),
    fileName: Yup.string().required('File name is required'),
    tags: Yup.string(),
});

export const editorModeList: string[] = [
    'Editor',
    'Administrator',
    'SuperAdministrator',
    'DHIAdmin'
];

export const deleteModeList: string[] = [
    'Administrator',
    'SuperAdministrator',
    'DHIAdmin'
]

export const displayDocuments = [
    {
        name: 'March',
        size: '1.42 mb',
        modified: '2024-03-08 05:43:41',
        type: 'folder',
      },
      {
        name: 'test.txt',
        size: '0 kb',
        modified: '2024-03-08 05:43:30',
        type: 'file',
      },
]

export type Directory = {
  name: string;
  fileSize: number;
  modifiedDateTime: string;
  subDirectories?: Directory[];
  files?: File[];
}

export type File = {
  id: string;
  name: string;
  fileSize: number;
  modifiedDateTime: string;
  isOnline?: boolean;
}