import React from 'react';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box, useMediaQuery } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

const DocumentNavigation: React.FC = () => {

    const { root, setRoot, setPath } = useDocumentView();
    const isSmallScreen = useMediaQuery('(max-width: 799px)')

    const handleRootChange = (event: SelectChangeEvent<string>) => {
        const newRoot = event.target.value;
        setRoot(newRoot);
        setPath([newRoot]);
    };

    return (
        <Box>
            <FormControl variant="outlined" size="small">
                <Select
                    value={root}
                    onChange={handleRootChange}
                    sx={{ fontSize: isSmallScreen ? '11px' : '14px' }}
                >
                    <MenuItem value="Documents" sx={{ fontSize: isSmallScreen ? '11px' : '14px' }}>Documents</MenuItem>
                    <MenuItem value="Uploads" sx={{ fontSize: isSmallScreen ? '11px' : '14px' }}>Uploads</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default DocumentNavigation;
