export const menuBarStyle = (isMobile: boolean): React.CSSProperties => ({
    display: isMobile ? 'none' : 'flex',
    flex: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

export const navLinkStyle = (isActive: boolean, isHome: boolean): React.CSSProperties => ({
    display: 'inline-block',
    width: '100px',
    fontSize: '16px',
    fontWeight: isActive ? 700 : 400,
    textDecoration: 'none',
    color: isActive ? '#F65E27' : isHome ? 'white' : 'black',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: isActive ? '3px solid #F65E27' : '3px solid transparent',
    backgroundColor: isActive ? '#F0E8E3' : 'transparent',
});

