import React from 'react';
import { Box, TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { Search, HighlightOff } from '@mui/icons-material';
import { useMapView } from '../../../context/map-context/mapContext';

const LayerFilter = () => {

    const { layerFilter, setLayerFilter } = useMapView()

    const handleFilterChange = React.useCallback((event: { target: { value: string; }; }) => {
        setLayerFilter(event.target.value.toLowerCase());
    }, [setLayerFilter]);

    const resetFilter = React.useCallback(() => {
        setLayerFilter('');
    }, [setLayerFilter]);

    const startAdornment = (
        <InputAdornment position="start">
            <Search />
        </InputAdornment>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid lightgray',
                padding: 1,
                margin: 2,
            }}
        >
            <TextField
                id="search-textfield"
                placeholder='Search...'
                variant="outlined"
                value={layerFilter}
                onChange={handleFilterChange}
                InputProps={{
                    startAdornment,
                    style: {
                        padding: 0,
                        height: 30,
                    }
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        padding: '0',
                        '& fieldset': {
                            borderColor: 'transparent',
                            padding: 0,
                            m: 0,
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                        '& input::placeholder': {
                            fontSize: '14px',
                        },
                        '&.MuiInputBase-input': {
                            padding: '0 !important',
                        },
                    },
                }}
            />

            <Tooltip title="Refresh">
                <IconButton
                    size="small"
                    aria-label="refresh input filter"
                    aria-controls="filter-dataset"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={resetFilter}
                >
                    <HighlightOff sx={{ color: '#C4C4C4' }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default LayerFilter