import React from 'react';
import Box from '@mui/material/Box';
import { useMapView } from '../../../context/map-context/mapContext';
import { Collapse, useMediaQuery } from '@mui/material';
import { imageStyle, mapStyleList } from './MapStyleData';

const BasemapButton = () => {

    const { mapStyle, setMapStyle } = useMapView();
    const [open, setOpen] = React.useState(false);
    const isSmallScreen = useMediaQuery('(min-width: 999px)')

    const currentAction = mapStyleList.find(item => item.mapStyle === mapStyle);

    return (
        <Box
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff',
                borderRadius: '4px',
                padding: isSmallScreen ? 1 : '1px',
                cursor: 'pointer',
                position: 'relative',
                boxSizing: 'border-box'
            }}
        >
            <img src={currentAction?.src} style={imageStyle} alt={currentAction?.name} title={currentAction?.name} />

            <Collapse in={open}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: 1,
                        boxSizing: 'border-box'
                    }}
                >
                    {mapStyleList.map((item) => (
                        <Box
                            key={item.name}
                            onClick={() => { setMapStyle(item.mapStyle); setOpen(false); }}
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                                '&:hover': {
                                    opacity: 0.8,
                                    transition: 'opacity 0.3s ease'
                                },
                                paddingTop: 1,
                            }}
                        >
                            <img src={item.src} style={{ ...imageStyle, border: '2px solid black' }} alt={item.name} title={item.name} />
                        </Box>
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
}

export default BasemapButton;
