import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useEventView } from '../../context/event-context/EventContext'
import EventContent from '../../components/layout/event-content/EventContent'
import ContentWrapper from '../../components/layout/content-wrapper/ContentWrapper'

const EventPage = () => {

  const { loading } = useEventView()
  
  return (
    <ContentWrapper>
      <EventContent />
      {
        loading && (
          <Backdrop
            open={loading}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }
    </ContentWrapper>
  )
}

export default EventPage