import { styled } from '@mui/system';
import { LinearProgress, CircularProgress, Stepper } from '@mui/material';

export const CustomStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepIcon-root.Mui-active': {
        color: '#f58e3f',
    },
    '& .MuiStepIcon-root.Mui-completed': {
        color: '#f58e3f',
    },
}));

export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '& .MuiLinearProgress-bar1Determinate': {
        width: '300px',
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: 0,
        transition: 'transform .4s linear',
        transformOrigin: 'left',
        backgroundColor: '#f58e3f',
    },
}));

export const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
    display: 'inline-block',
    transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#f58e3f',
    marginLeft: '10px',
}));

export const uploadBoxStyle = {
    mb: 2,
    width: '100%',
    height: 200,
    border: '1px dashed lightgray',
    backgroundColor: '#f2f6f9',
    color: '#86A2B3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

export const uploadButtonStyle = {
    color: '#fff',
    borderColor: '#f58e3f',
    backgroundColor: '#f58e3f',
    borderRadius: 2,
    marginTop: '16px',
    textTransform: 'capitalize',
    ':hover': {
        color: '#fff',
        borderColor: '#f58e3f',
        backgroundColor: '#f58e3f',
        opacity: 0.8,
    },
};

export const stepButtonStyle = (activeStep: number, step: number, isSmallScreen: boolean) => ({
    color: '#fff',
    fontSize: isSmallScreen ? '12px' : '11px',
    backgroundColor: activeStep !== step ? 'lightgray' : '#f58e3f',
    borderColor: activeStep !== step ? 'lightgray' : '#f58e3f',
    borderRadius: 2,
    marginLeft: 2,
    textTransform: 'capitalize',
    ':hover': {
        color: '#fff',
        backgroundColor: '#f58e3f',
        borderColor: '#f58e3f',
        opacity: 0.8,
    },
});

export const additionalFileButtonStyle = (activeStep: number, step: number, isSmallScreen: boolean) => ({
    color: '#000',
    fontSize: isSmallScreen ? '12px' : '11px',
    backgroundColor: activeStep !== step ? 'lightgray' : '#CFDBE2',
    borderColor: activeStep !== step ? 'lightgray' : '#CFDBE2',
    borderRadius: 2,
    marginLeft: 2,
    textTransform: 'capitalize',
    ':hover': {
        color: '#000',
        borderColor: '#CFDBE2',
        backgroundColor: '#CFDBE2',
        opacity: 0.8,
    },
});

export const uploadSelectedFilesButtonStyle = (additionalFilesLength: number) => ({
    color: '#fff',
    backgroundColor: additionalFilesLength === 0 ? '#CFDBE2' : '#f58e3f',
    borderColor: additionalFilesLength === 0 ? '#CFDBE2' : '#f58e3f',
    borderRadius: 2,
    textTransform: 'capitalize',
    marginRight: 2,
    ':hover': {
        color: '#fff',
        backgroundColor: additionalFilesLength === 0 ? '#CFDBE2' : '#f58e3f',
        borderColor: additionalFilesLength === 0 ? '#CFDBE2' : '#f58e3f',
        opacity: 0.8,
    },
});

export const resetButtonStyle = {
    color: '#fff',
    borderColor: '#f58e3f',
    backgroundColor: '#f58e3f',
    borderRadius: 2,
    textTransform: 'capitalize',
    ':hover': {
        color: '#fff',
        borderColor: '#f58e3f',
        backgroundColor: '#f58e3f',
        opacity: 0.8,
    },
};
