import SatelliteIcon from '../../../asset/Images/satellite.png';
import LightIcon from '../../../asset/Images/light.png';
import DarkIcon from '../../../asset/Images/dark.png';
import StreetIcon from '../../../asset/Images/street.png';

export interface MapStyle {
    src: string;
    name: string;
    mapStyle: string;
}

export type MapStyleList = MapStyle[];

export const mapStyleList: MapStyleList = [
    {
        src: SatelliteIcon,
        name: 'Satellite',
        mapStyle: 'mapbox://styles/mapbox/satellite-v9?optimize=true'
    },
    {
        src: StreetIcon,
        name: 'Streets',
        mapStyle: 'mapbox://styles/mapbox/streets-v12?optimize=true'
    },
    {
        src: LightIcon,
        name: 'Light',
        mapStyle: 'mapbox://styles/mapbox/light-v11?optimize=true'
    },
    {
        src: DarkIcon,
        name: 'Dark',
        mapStyle: 'mapbox://styles/mapbox/dark-v11?optimize=true'
    }
];

export const imageStyle = {
    width: 25,
    height: 25,
    borderRadius: 5,
    border: '2px solid black',
    transition: 'opacity 0.3s ease'
};