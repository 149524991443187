// src/components/DocumentListView/FileRow.tsx
import React from 'react';
import { TableCell, TableRow, Box, TextField, IconButton, Tooltip } from '@mui/material';
import { InsertDriveFile as InsertDriveFileIcon, Download, ModeEdit, Clear, Save, Cancel } from '@mui/icons-material';
import { formatDate, formatSize } from '../../../utils/Utils';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { deleteModeList, editorModeList, File } from '../../../pages/document-page/DocumentViewLib';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import { handleCancelClick, handleClickClearButton, handleEditClick, handleSaveClick } from '../../../utils/DocumentUtils';

interface FileRowProps {
    file: File;
    editing: boolean;
    editFileId: any;
    editFileName: string;
    setOpenDeleteConfirmation: (val: boolean) => void;
    setFileId: (val: string) => void;
    setEditing: (val: boolean) => void;
    setEditFileId: (val: string | null) => void;
    setEditFileName: (name: string) => void;
}

const FileRow: React.FC<FileRowProps> = ({
    file,
    editing,
    editFileId,
    editFileName,
    setOpenDeleteConfirmation,
    setFileId,
    setEditing,
    setEditFileId,
    setEditFileName,
}) => {
    const { user } = useAuth();
    const { downLoadDocument, renameDocument } = useDocumentView()

    const hasEditPermission = Array.isArray(user?.roles)
        ? user.roles.some((role: string) => editorModeList.includes(role))
        : editorModeList.includes(user?.roles as string);

    const hasDeletePermission = Array.isArray(user?.roles)
        ? user.roles.some((role: string) => deleteModeList.includes(role))
        : deleteModeList.includes(user?.roles as string);

    return (
        <TableRow>
            <TableCell component="th" scope="row" style={{ paddingLeft: `10px` }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InsertDriveFileIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                    {editing && editFileId === file.id ? (
                        <TextField
                            value={editFileName}
                            onChange={(e) => setEditFileName(e.target.value)}
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    '& .MuiInputBase-input': {
                                        fontSize: 12,
                                        margin: 0,
                                        padding: 0,
                                        height: 30,
                                    },
                                    '& .MuiFilledInput-root': {
                                        '&:before': {
                                            borderBottom: 'none',
                                        },
                                        '&:after': {
                                            borderBottom: 'none',
                                        },
                                        '&:hover:not(.Mui-disabled):before': {
                                            borderBottom: 'none',
                                        },
                                    },
                                },
                            }}
                        />
                    ) : (
                        file.name
                    )}
                </Box>
            </TableCell>
            <TableCell align="right">{formatSize(file.fileSize)}</TableCell>
            <TableCell align="right">{formatDate(file.modifiedDateTime)}</TableCell>
            <TableCell align="right">
                {editing && editFileId === file.id ? (
                    <Box sx={{ minWidth: 120 }}>
                        <Tooltip title="Save">
                            <IconButton
                                aria-label="save"
                                size="small"
                                onClick={() =>
                                    handleSaveClick(
                                        editFileId,
                                        setEditing,
                                        setEditFileId,
                                        setEditFileName,
                                        () => renameDocument(editFileId, editFileName)
                                    )}
                            >
                                <Save />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <IconButton
                                aria-label="cancel"
                                size="small"
                                onClick={() =>
                                    handleCancelClick(
                                        setEditing,
                                        setEditFileId,
                                        setEditFileName
                                    )}
                            >
                                <Cancel />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box sx={{ minWidth: 120 }}>
                        <Tooltip title="Download">
                            <IconButton
                                aria-label="download"
                                size="small"
                                onClick={() => downLoadDocument(file.id)}>
                                <Download />
                            </IconButton>
                        </Tooltip>
                        {hasEditPermission && (
                            <Tooltip title="Rename">
                                <IconButton
                                    aria-label="edit"
                                    size="small"
                                    onClick={() =>
                                        handleEditClick(
                                            file,
                                            setEditing,
                                            setEditFileId,
                                            setEditFileName
                                        )}
                                >
                                    <ModeEdit />
                                </IconButton>
                            </Tooltip>
                        )}
                        {hasDeletePermission && (
                            <Tooltip title="Delete">
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() =>
                                        handleClickClearButton(
                                            file.id,
                                            setOpenDeleteConfirmation,
                                            setFileId
                                        )}>
                                    <Clear sx={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default FileRow;
