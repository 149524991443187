import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material';
import DataPointContent from '../../components/layout/data-point-content/DataPointContent';
import { useDataPointView } from '../../context/data-point-context/DataPointContext';
import ContentWrapper from '../../components/layout/content-wrapper/ContentWrapper';

const DataPointPage = () => {

    const { loading } = useDataPointView()
    
    return (
        <ContentWrapper>
            <DataPointContent />
            {
                loading && (
                    <Backdrop
                        open={loading}
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }
        </ContentWrapper>
    )
}

export default DataPointPage