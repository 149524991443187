import React from 'react';
import { Card, CardActions, IconButton, Collapse, Divider, Box, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Text from '../../common/text/Text';
import useDraggable from '../../../hooks/useDraggable';
import Draggable from 'react-draggable';
import { LegendData, legendData } from './LegendData';
import {
    cardStyle,
    cardActionsStyle,
    textStyle,
    stackStyle,
    legendItemStyle,
    imageStyle,
    boxStyle,
    legendTextStyle,
    onlineStyle,
    outsideThresholdStyle,
    offlineStyle,
} from './Style';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useMapView } from '../../../context/map-context/mapContext';

const MapLegend: React.FC = () => {

    const { user } = useAuth();
    const { isShowTimeSlider } = useMapView()
    const [expanded, setExpanded] = React.useState(false);
    const { trackPos } = useDraggable();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const filteredLegendData = legendData.filter((item: LegendData) => {
        const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];
        return item.roles?.some((role: string) => userRoles.includes(role));
    });

    const renderLegendItem = (item: LegendData) => {
        const getBoxStyle = (type: number, color: string | null) => {
            switch (type) {
                case 1:
                    return boxStyle(color || '');
                case 2:
                    return onlineStyle(color || '');
                case 3:
                    return offlineStyle(color || '');
                case 4:
                    return outsideThresholdStyle(color || '');
                default:
                    return {};
            }
        };

        return (
            <Stack key={item.id} sx={legendItemStyle}>
                {item.type === 0 && item.imageUrl && (
                    <img src={item.imageUrl} alt='station-status-icon' style={imageStyle} />
                )}
                {[1, 2, 3, 4].includes(item.type) && (
                    <Box sx={getBoxStyle(item.type, item.color)}></Box>
                )}
                <Text variant='caption' sx={legendTextStyle}>{item.name}</Text>
            </Stack>
        );
    };

    return (
        <Draggable onDrag={(e, data) => trackPos(data)} cancel=".no-drag">
            <Card sx={cardStyle(isShowTimeSlider)}>
                <CardActions disableSpacing sx={cardActionsStyle}>
                    <Text variant='caption' sx={textStyle}>Map Legend</Text>
                    <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" className="no-drag">
                        {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <Stack sx={stackStyle}>
                        {filteredLegendData.map(renderLegendItem)}
                    </Stack>
                </Collapse>
            </Card>
        </Draggable>
    );
};

export default MapLegend;
