import { Box } from '@mui/material'
import React from 'react'
import sfaLogoMonowhite from '../../../asset/Logo/sfa_logo_text_monowhite.png'
import Text from '../../common/text/Text'

const LoginHeader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <img src={sfaLogoMonowhite} alt='singapore-food-agency' style={{ height: 100 }} />
            <Text
                variant='h6'
                sx={{
                    color: 'white',
                    margin: 4
                }}
            >
                Aquaculture Sensing Network (ASN) Portal
            </Text>
        </Box>
    )
}

export default LoginHeader