import React from 'react';
import { useUploadView } from '../../../context/upload-context/UploadContext';
import UploadWrapper from './UploadWrapper';
import { fileAccept, additionalFileAccept } from './UploadMenu';

const NutrientSurvey: React.FC = () => {
    const { loading, downloadTemplateNutrientSurveyResult, uploadNutrientSurvey, uploadAdditionalNutrientSurvey } = useUploadView();

    const handleUploadFile = async (file: File): Promise<void> => {
        await uploadNutrientSurvey(file);
    };

    const handleUploadAdditionalFiles = async (files: File[]): Promise<void> => {
        await uploadAdditionalNutrientSurvey(files);
    };

    return (
        <UploadWrapper
            title="Nutrient Survey Result Upload"
            templateDownload={downloadTemplateNutrientSurveyResult}
            uploadFile={handleUploadFile}
            uploadAdditionalFile={handleUploadAdditionalFiles}
            fileAccept={fileAccept}
            additionalFileAccept={additionalFileAccept}
            anotherButtonText="Upload Another Nutrient Survey"
            loading={loading}
        />
    );
};

export default NutrientSurvey;
