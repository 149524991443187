import sensor from '../../../asset/Icon/station/water quality/waterquality.png';
import nutrient from '../../../asset/Icon/station/nutrient survey/nutrientsurvey.png';
import plankton from '../../../asset/Icon/station/plankton survey/planktonsurvey.png';
import sediment from '../../../asset/Icon/station/sediment quality/sedimentquality.png';

export interface LegendData {
    id: number;
    name: string;
    type: number;
    imageUrl: string | null;
    color: string | null;
    roles: string[];
}

export const legendData: LegendData[] = [
    {
        id: 0,
        name: 'Sensor',
        type: 0,
        imageUrl: sensor,
        color: null,
        roles: ['FishFarmer', 'SFAAgency', 'SFAGeneral', 'Editor', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 1,
        name: 'Nutrient',
        type: 0,
        imageUrl: nutrient,
        color: null,
        roles: ['Editor', 'SFAAgency', 'SFAGeneral', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 2,
        name: 'Plankton',
        type: 0,
        imageUrl: plankton,
        color: null,
        roles: ['Editor', 'SFAGeneral', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 3,
        name: 'Sediment',
        type: 0,
        imageUrl: sediment,
        color: null,
        roles: ['Editor', 'SFAGeneral', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 4,
        name: 'Online',
        type: 2,
        imageUrl: null,
        color: 'green',
        roles: ['FishFarmer', 'SFAAgency', 'SFAGeneral', 'Editor', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 5,
        name: 'Offline',
        type: 3,
        imageUrl: null,
        color: 'grey',
        roles: ['FishFarmer', 'SFAAgency', 'SFAGeneral', 'Editor', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 6,
        name: 'Outside Threshold',
        type: 4,
        imageUrl: null,
        color: 'red',
        roles: ['FishFarmer', 'SFAAgency', 'SFAGeneral', 'Editor', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    },
    {
        id: 10,
        name: 'Fish Farms',
        type: 1,
        imageUrl: null,
        color: '#5c8ab9',
        roles: ['FishFarmer', 'SFAAgency', 'SFAGeneral', 'Editor', 'Administrator', 'DHIAdmin', 'SuperAdministrator'],
    }
];
