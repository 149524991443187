import { UserRoles } from "../../feature/menu-bar/MenuData";

export interface DataType {
    label: string;
    name: string;
    value: number;
    roles: string[];
}

export const dataTypeMenu: DataType[] = [
    {
        label: "Online Water Quality Monitoring",
        name: "WaterQuality",
        value: 0,
        roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        label: "Plankton Survey",
        name: "PlanktonSurvey",
        value: 1,
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        label: "Nutrient Survey Result",
        name: "NutrientSurveyResult",
        value: 2,
        roles: ["SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    },
    {
        label: "Sediment Quality",
        name: "SedimentQuality",
        value: 3,
        roles: ["Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
    }
]

const getDataTypeData = (user: UserRoles | null): DataType[] => {
    if (!user) {
        return []
    }

    const userRoles = Array.isArray(user.roles) ? user.roles : [user.roles];

    return dataTypeMenu.filter(item =>
        userRoles.some(role => item.roles.includes(role))
    );
};

export { getDataTypeData };