import React, { useState, createContext, useContext } from 'react';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';

interface DataPointViewContextType {
    loading: boolean;
    getStationDetails: (dataType: string) => void;
    selectedDataType: string;
    setSelectedDataType: (val: string) => void;
    listStationsDetails: any;
    stationFilter: string;
    setStationFilter: (val: string) => void;
}

const DataPointContext = createContext<DataPointViewContextType | undefined>(undefined);

export const DataPointViewProvider = ({ children }: { children: React.ReactNode }) => {
    
    const fetchWithAuth = useFetchWithAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [stationFilter, setStationFilter] = useState<string>('');
    const [selectedDataType, setSelectedDataType] = useState<string>('WaterQuality');
    const [listStationsDetails, setListStationsDetails] = useState<any>([]);


    const getStationDetails = async (dataType: string) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/Stations/Details?dataType=${dataType}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch station data for ${dataType}`);
            }

            const data = await response.json();
            setListStationsDetails(data)
        } catch (err) {
            console.error(`Error fetching ${dataType}:`, err);
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <DataPointContext.Provider
            value={{
                loading,
                getStationDetails,
                selectedDataType,
                setSelectedDataType,
                listStationsDetails,
                stationFilter,
                setStationFilter
            }}
        >
            {children}
        </DataPointContext.Provider>
    );
};

export const useDataPointView = () => {
    const context = useContext(DataPointContext);
    if (context === undefined) {
        throw new Error('useDataPointView must be used within a DataPointViewProvider');
    }
    return context;
};
