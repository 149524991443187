export type SortConfig = {
    key: string | null;
    direction: 'ascending' | 'descending' | null;
};

export type DataRow = {
    id: number;
    type: number;
    description: string;
    startedDateTime: string;
    reportedDateTime: string;
    completedDateTime: string;
    createdBy: string;
    status: number;
    assignedTo: string;
    assignedUserId: string;
    remarks: string;
};

export const handleChangePage = (
    setPage: (val: number) => void
) => (event: unknown, newPage: number) => {
    setPage(newPage);
};

export const handleChangeRowsPerPage = (
    setRowsPerPage: (val: number) => void,
    setPage: (val: number) => void
) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

export const handleSort = (key: string, sortConfig: SortConfig, events: any[], setSortConfig: (config: SortConfig) => void, setOrderBy: (key: string) => void, setEvents: (events: any[]) => void) => {
    let direction: 'ascending' | 'descending' = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
    }

    setSortConfig({ key, direction });
    setOrderBy(key);

    const sortedEvents = [...events].sort((a, b) => {
        if (a[key] < b[key]) {
            return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    setEvents(sortedEvents);
};

export const handleFilterClose = (setAnchorEl: (value: null | HTMLElement) => void) => {
    setAnchorEl(null);
};

export const handleFilterClick = (
    event: React.MouseEvent<HTMLElement>,
    field: any,
    setAnchorEl: (value: HTMLElement) => void,
    setFilterLabel: (value: string) => void,
    setFilterField: (value: string | number | Date | null) => void,
    setFilterValue: (value: string | number | Date) => void
) => {
    setAnchorEl(event.currentTarget);
    setFilterLabel(field.label);
    setFilterField(field.value);
    setFilterValue('');
};

export const handleFilterApply = (
    filterField: string | number | Date | null,
    filterValue: string | number | Date,
    setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string | number | Date }>>,
    handleFilterClose: () => void
) => {
    if (filterField) {
        setFilters((prev: { [key: string]: string | number | Date }) => ({
            ...prev,
            [filterField as string]: filterValue
        }));
    }
    handleFilterClose();
};

export const handleClickEdit = (
    id: number, 
    setEditingRow: React.Dispatch<React.SetStateAction<number | null>>, 
    setEditableValues: React.Dispatch<React.SetStateAction<{ [key: number]: Partial<DataRow> }>>, 
    events: DataRow[]
) => {
    setEditingRow(id);
    setEditableValues(prev => ({
        ...prev,
        [id]: { ...events.find((event: { id: number; }) => event.id === id) }
    }));
};

export const handleClickCancel = (
    setEditingRow: React.Dispatch<React.SetStateAction<number | null>>
) => {
    setEditingRow(null);
};

export const handleSave = (
    id: number, 
    events: DataRow[], 
    editableValues: { [key: number]: Partial<DataRow> }, 
    saveEvent: (id: number, updatedEvent: DataRow) => void, 
    setEditingRow: React.Dispatch<React.SetStateAction<number | null>>, 
    setEditableValues: React.Dispatch<React.SetStateAction<{ [key: number]: Partial<DataRow> }>>
) => {
    const originalEvent = events.find((event: { id: number }) => event.id === id);

    if (!originalEvent) {
        console.error(`Event with id ${id} not found.`);
        return;
    }

    const updatedEvent: DataRow = {
        ...originalEvent,
        ...editableValues[id],
        assignedUserId: editableValues[id]?.assignedUserId || originalEvent.assignedUserId,
        assignedTo: editableValues[id]?.assignedTo || originalEvent.assignedTo,
        id: originalEvent.id,
    };

    saveEvent(id, updatedEvent);
    setEditingRow(null);
    setEditableValues({});
};

export const handleEditableChange = (
    id: number, 
    field: keyof DataRow, 
    value: string | number, 
    assignedToList: Array<{ id: string, firstName: string, lastName: string }>, 
    setEditableValues: React.Dispatch<React.SetStateAction<{ [key: number]: Partial<DataRow> }>>
) => {
    setEditableValues(prev => {
        if (field === 'assignedTo') {
            const assignedUser = assignedToList.find(item => item.id === value);
            const assignedUserId = assignedUser ? assignedUser.id : '';

            return {
                ...prev,
                [id]: {
                    ...prev[id],
                    assignedTo: assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName}` : '',
                    assignedUserId: assignedUserId
                }
            };
        }

        return {
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        };
    });
};

export const filterEvents = (events: any[], filters: { [key: string]: string | number | Date }) => {
    return events.filter((event: { [x: string]: any }) => {
        return Object.keys(filters).every(key => {
            const fieldValue = event[key];
            const filterValue = filters[key];

            if (typeof filterValue === 'string') {
                return fieldValue !== undefined && fieldValue !== null
                    ? fieldValue.toString().toLowerCase().includes(filterValue.toLowerCase())
                    : false;
            }

            return fieldValue !== undefined && fieldValue !== null
                ? fieldValue.toString().includes(filterValue.toString())
                : false;
        });
    });
};

export const sortEvents = (filteredEvents: any[], eventFilter: string) => {
    return filteredEvents.filter((item: any) => (
        (item.id?.toString() ?? '').includes(eventFilter) ||
        (item.description?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.createdBy?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.assignedTo?.toLowerCase() ?? '').includes(eventFilter) ||
        (item.remarks?.toLowerCase() ?? '').includes(eventFilter)
    ));
};

export const paginateEvents = (sortedData: any[], page: number, rowsPerPage: number) => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};
