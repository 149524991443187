export const disclaimerText = [
    {
        id: 0,
        text: "Disclaimer",
        subText: "Please read the following terms and conditions carefully before using this web site. The following terms and conditions apply to this web site. By using this site, you consent to these terms and condition on use. These terms and conditions are subject to change from time to time, as deemed necessary by Info@SEA Pte. Ltd."
    },
    {
        id: 1,
        text: "LIABILITY",
        subText: 'The information provided on this website is provided "as is," without an express or implied warranties, including but not limited to, warranties of merchantability or fitness for a particular purpose. Info@SEA Pte. Ltd. does not guarantee that CIRCUMSTANCES SHALL Info@SEA Pte. Ltd. BE LIABLE FOR ANY DIRECT OR INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES THAT MAY ARISE FROM YOUR USE OF, RELIABILITY ON OR INABILITY TO USE, THIS WEB SITE. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages. In such jurisdictions, the liability of Info@SEA Pte. Ltd. is limited to the license fee paid by client. Info@SEA Pte. Ltd. does not guarantee or warrant that files accessed on this web site, and /or available for downloading from this site, are or will be free of viruses, worms, Trojan horses or other contaminating or destructive properties. You access and download information from this site at your own risk. The information provided on this site is displayed on electronic charts. These charts are not to be used for navigational purposes Info@SEA Pte Ltd will not be responsible, under any theory of liability or indemnity, for your use of, or reliance on, the electronic charts on this site.'
    },
    {
        id: 2,
        text: "LINKS TO THIRD PARTY WEB SITES",
        subText: "Info@SEA Pte. Ltd. may provide links to certain third party web sites that we believe may be of interest to our users. These links are provided for your convenience and information only. Info@SEA Pte. Ltd. does not control and is not responsible for the content, accuracy or privacy policies of these linked web sites and use of these websites is at your own risk. Info@SEA Pte. Ltd. reserves the right to terminate a link to a third party web site at any time by the fact that Info@SEA Pte. Ltd. has provided a link to a third party web site does not necessarily mean that it is endorsed, authorised or sponsored or that Info@SEA Pte. Ltd. is affiliated with the third party web site's owners or sponsors."
    },
    {
        id: 3,
        text: "PROPRIETARY RIGHTS & RESTRICTED USE",
        subText: "This web site's content is protected by trademark laws, the laws of privacy and publicity, and communication regulations and statutes. You are not authorised to post on or transmit to or from this web site any unlawful, threatening, libellous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material, or any other content that could give rise to any civil or criminal liability under the law. You may print a copy of the information contained herein for your personal use only. But you may not: 1. Use the name Info@SEA Pte. Ltd., Info@SEA or our logo in any way, including in advertising or publicity pertaining to distribution of materials on this web site. 2. Hyperlink to this website; reproduce or distribute the test or graphics to others. 3. Substantially copy the information onto you own server. without the prior written permission of Info@SEA Pte. Ltd. and then only if any and all service marks and other proprietary notices are kept intact. You may request permission by sending a request to us via email or our online contact form."
    },
    {
        id: 4,
        text: "UPDATES",
        subText: "Please review this notice periodically for changes and updates. Info@SEA Pte. Ltd. reserves the right to make changes to any section of this website, including this Legal Notice and Disclaimer section."
    },
    {
        id: 5,
        text: "CHOICE OF LAW AND FORUM PROVISIONS",
        subText: "This website resides on a server in Singapore. You agree that these Terms and your use of this website are governed and construed in accordance with laws of Singapore. You hereby consent to the jurisdiction and venue of the Singapore courts in all disputes (a) arising out of, relating to, or concerning this website and/or these Terms, (b) in which this website and/or these Terms is an issue or a material fact, or (c) in which this website and/or these Terms is referenced in a paper filed in a court, tribunal, agency or other dispute resolution organisation. We have endeavoured to comply with all legal requirements known to us in creating and maintaining this website, but make no representation that materials on this website are appropriate or available for use in any particular jurisdiction. You are responsible for compliance with applicable laws. Any use in contravention of this provision or any provision of these Terms is at your own risk and, if any part of these Terms is invalid or unenforceable under applicable law, the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms shall govern such use."
    }
]