import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const cardStyle = (showTimeslider: boolean) : SxProps<Theme> => ({
    width: 300,
    position: 'absolute',
    bottom: showTimeslider ? 110 : 5,
    right: 5,
    zIndex: 3,
});

export const cardActionsStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
};

export const textStyle: SxProps<Theme> = {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#555555',
    marginLeft: '2px',
};

export const stackStyle: SxProps<Theme> = {
    flexDirection: 'column',
    padding: 1,
    gap: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
};

export const legendItemStyle: SxProps<Theme> = {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
};

export const imageStyle: CSSProperties = {
    display: 'block',
    width: 20,
};

export const boxStyle = (color: string): SxProps<Theme> => ({
    width: 20,
    height: 20,
    borderRadius: 1,
    backgroundColor: color,
});

export const legendTextStyle: SxProps<Theme> = {
    fontSize: 11,
    color: '777777',
    marginLeft: 1,
    textAlign: 'left',
};

export const onlineStyle = (color: string): SxProps<Theme> => ({
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: `3px solid ${color}`
})

export const offlineStyle = (color: string): SxProps<Theme> => ({
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: `3px solid ${color}`
})

export const outsideThresholdStyle = (color: string): SxProps<Theme> => ({
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: color,
    border: `3px solid ${color}`
})