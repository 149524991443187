import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import SendMessage from '../../components/feature/send-message/SendMessage'
import ContentWrapper from '../../components/layout/content-wrapper/ContentWrapper'

const ContactUsPage = () => {

  const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');

  return (
    <ContentWrapper>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: isTabletOrDesktop ? 'row' : 'column',
          boxSizing: 'border-box',
          overflowY: 'auto'
        }}
      >
        <SendMessage />
      </Box>
    </ContentWrapper>
  )
}

export default ContactUsPage