import React, { useState, useEffect, useCallback } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { JwtToken, useAuth } from '../../../context/auth-context/AuthContext'
import { jwtDecode } from 'jwt-decode'

interface Props {
  children?: React.ReactNode
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { user, setUser, createNewUserData, setRefreshTokenTimeout } = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  const clearUserAndTokens = useCallback(() => {
    setUser(null)
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }, [setUser])

  const checkAndSetUser = useCallback(() => {
    if (!user) {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token')
      if (token) {
        try {
          const decodedToken = jwtDecode<JwtToken>(token)
          if (Date.now() / 1000 < decodedToken.exp) {
            const newUserData = createNewUserData(decodedToken, token)
            setUser(newUserData)
            setRefreshTokenTimeout(decodedToken.exp)
          } else {
            clearUserAndTokens()
          }
        } catch (error) {
          clearUserAndTokens()
        }
      }
    }
    setIsLoading(false)
  }, [user, setUser, clearUserAndTokens])

  useEffect(() => {
    checkAndSetUser()
  }, [checkAndSetUser])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!user) {
    return <Navigate to="/login" replace />
  }

  return children ? <>{children}</> : <Outlet />
}

export default ProtectedRoute
