import { CSSProperties } from 'react';

export const iconStyle: CSSProperties = {
    width: 20,
    height: 20,
    cursor: 'pointer',
    position: 'absolute',
};

export const textStyle: CSSProperties = {
    margin: 0,
    color: '#000',
    fontSize: '11px',
    textShadow: '1px 1px 1px #fff, -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff',
    position: 'absolute',
    top: 30,
};
