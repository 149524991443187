import React from 'react';
import earth from '../../../asset/Images/earth.png';
import plankton from '../../../asset/Images/plankton.jpg';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Text from '../../common/text/Text';

type TextContentType = {
    variant: "h2" | "body1" | "button" | "caption" | "h1" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body2" | "overline";
    text: string;
    image: string;
}

const LandingPageBottom = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const textContents: TextContentType[] = [
        {
            variant: "body1",
            text: "At our discrete water sampling stations, we also monitor nutrient parameters that are vital for aquatic life and ecosystem health. Routine monitoring allows us to proactively maintain water quality and sustain aquatic environments.",
            image: earth
        },
        {
            variant: "body1",
            text: "At our plankton sampling stations, we monitor harmful algal bloom (HAB) occurrences. By tracking HABs, we can quickly respond to potential risk and protect the farm produce and also human health.",
            image: plankton
        },
    ];

    return (
        <Box
            display='flex'
            flexDirection={isSmallScreen || isMediumScreen ? 'column' : 'row'}
            width='100%'
            height={600}
            flexWrap='wrap'
            marginTop={10}
        >
            {textContents.map(({ variant, text, image }, index) => (
                <Box
                    key={index}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: isSmallScreen || isMediumScreen ? '500px' : '100%',
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        boxSizing: 'border-box',
                        marginBottom: isSmallScreen || isMediumScreen ? 2 : 0,
                    }}
                >
                    <Text
                        variant={variant}
                        sx={{
                            color: 'white',
                            backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,1))',
                            padding: 1,
                            minHeight: isSmallScreen || isMediumScreen ? 'fit-content' : '100px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            fontSize: isSmallScreen ? '11px' : isMediumScreen ? '12px' : '14px',
                        }}
                    >
                        {text}
                    </Text>
                </Box>
            ))}
        </Box>
    );
}

export default LandingPageBottom;
