export const forbiddenToolboxAccessRoles = ['FishFarmer']

export type ObservationData = {
    dateTime: string
    value: number
}

export type Observations = {
    timeseriesId: string
    dataType: string
    station: string
    parameter: string
    unit: string
    data: ObservationData[]
    minimumThreshold?: number | null
    maximumThreshold?: number | null
    minimumValue: number
    maximumValue: number
    source?: string
    rawTimeseriesId?: string | null
}

export type StatisticType = {
    label: string
    name: string
    value: number
}

export type DataType = {
    id: number
    name: string
    description: string
}

export type StationInfo = {
    selectedPeriod: string
    selectedDataType: string
    selectedStation: string[]
    selectedParameters: string[]
    selectedArea: string | null
}
