import * as Yup from 'yup';

export interface SendMessageFormValues {
  name: string;
  email: string;
  subject: string;
  category: string;
  enquiry: string;
  recaptcha?: string;
}

export interface Category {
  value: string;
  label: string;
}

export const messageValidationSchema = Yup.object({
  name: Yup.string().required('Name is required').max(50, 'Name must be 50 characters or less'),
  email: Yup.string().email('Enter a valid email').required('Email is required').max(50, 'Email must be 50 characters or less'),
  subject: Yup.string().required('Subject is required').max(200, 'Subject must be 200 characters or less'),
  category: Yup.string().required('Category is required'),
  enquiry: Yup.string().required('Enquiry is required').max(500, 'Enquiry must be 500 characters or less'),
});