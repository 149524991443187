import React, { Fragment } from 'react'
import LoginHeader from '../../feature/login-header/LoginHeader'
import LoginForm from '../../feature/login-form/LoginForm'

const LoginContainer = () => {
    return (
        <Fragment>
            <LoginHeader />
            <LoginForm />
        </Fragment>
    )
}

export default LoginContainer