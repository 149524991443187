import React, { useState } from 'react';
import { Box, Tab, Tabs as MuiTabs, styled, Tooltip, useMediaQuery } from '@mui/material';
import { TabTimeseriesProps } from './TimeseriesMenu';
import ButtonWithIcon from '../../common/button/Button';
import { Fullscreen, FullscreenExit, Delete, Add } from '@mui/icons-material';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';

const CustomTabContainer = styled(Box)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        marginLeft: 20,
        marginTop: 5,
    },
    '& .MuiTabs-root': {
        overflow: 'hidden',
        marginBottom: 0,
        borderBottom: '4px solid #CFDBE2',
        display: 'flex',
    },
    '& .MuiTab-root': {
        marginTop: 5,
        marginBottom: 0,
        textTransform: 'capitalize',
    },
    '& .MuiTab-root.Mui-selected': {
        color: '#555555',
        fontWeight: 700,
        backgroundColor: '#CFDBE2',
        margin: 5,
        marginBottom: 0,
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TimeseriesTab: React.FC<TabTimeseriesProps> = ({ tabs, addComponent, removeComponent }) => {

    const { isFullscreen, setIsFullscreen } = useTimeseriesView();
    const [value, setValue] = useState<number>(0);
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Tooltip title={isFullscreen ? (isSmallScreen ? "Exit Full Height" : "Exit Full Width") : (isSmallScreen ? "Full Height" : "Full Width")}>
                <Box
                    onClick={() => setIsFullscreen(!isFullscreen)}
                    sx={{
                        width: 30,
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        zIndex: 5,
                    }}
                >
                    {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                </Box>
            </Tooltip>
            <CustomTabContainer>
                <MuiTabs value={value} onChange={handleChange} aria-label="timeseries tab">
                    {tabs.map((tab, index) => (
                        <Tab label={tab.label} {...a11yProps(index)} key={index} />
                    ))}
                </MuiTabs>
            </CustomTabContainer>
            <Box
                sx={{
                    overflowY: 'auto',
                    height: isFullscreen ? '100vh' : 'calc(100vh - 100px)',
                    marginBottom: isFullscreen ? 0 : 5,
                    padding: 2,
                }}
            >
                {tabs[value]?.components.map((Component, index) => (
                    <Box
                        key={index}
                        marginBottom={20}
                        sx={{
                            overflow: 'hidden',
                            marginTop: index > 0 ? -10 : 0,
                        }}
                    >
                        <Box textAlign={'right'}>
                            <ButtonWithIcon
                                variant='outlined'
                                icon={<Add />}
                                onClick={() => addComponent(value)}
                                text='Add Chart'
                                sx={{
                                    color: '#f58e3f',
                                    fontSize: isSmallScreen ? 11 : 14,
                                    borderColor: '#f58e3f',
                                    borderRadius: 2,
                                    ':hover': {
                                        color: '#fff',
                                        borderColor: '#f58e3f',
                                        backgroundColor: '#f58e3f',
                                        opacity: 0.8,
                                    },
                                }}
                            />
                            {index !== 0 && (
                                <ButtonWithIcon
                                    variant='outlined'
                                    icon={<Delete />}
                                    onClick={() => removeComponent(value, index)}
                                    text='Remove Chart'
                                    sx={{
                                        color: '#f58e3f',
                                        fontSize: isSmallScreen ? 11 : 14,
                                        borderColor: '#f58e3f',
                                        borderRadius: 2,
                                        marginLeft: 2,
                                        ':hover': {
                                            color: '#fff',
                                            borderColor: '#f58e3f',
                                            backgroundColor: '#f58e3f',
                                            opacity: 0.8,
                                        },
                                    }}
                                />
                            )}
                        </Box>
                        {Component}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default TimeseriesTab;
