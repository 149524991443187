import { Alert, Box, Snackbar } from '@mui/material';
import React from 'react';
import UploadMenu from '../../components/layout/upload-content/UploadMenu';
import { useAuth } from '../../context/auth-context/AuthContext';
import ContentWrapper from '../../components/layout/content-wrapper/ContentWrapper';

const UploadPage = () => {

  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth();
  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';
  
  return (
    <ContentWrapper>
      <UploadMenu />
      {snackbarOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{ position: 'relative' }}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={alertSeverity}
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </ContentWrapper>
  );
}

export default UploadPage;
