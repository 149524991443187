import { useState } from 'react';

const useRecaptchaV3 = (siteKey: string) => {
    const [token, setToken] = useState<string | null>(null);

    const executeRecaptcha = async () => {
        if ((window as any).grecaptcha) {
            const token = await (window as any).grecaptcha.execute(siteKey, { action: 'submit' });
            setToken(token);
        }
    };

    return { token, executeRecaptcha };
};

export default useRecaptchaV3;
