import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { styled } from '@mui/system';
import { Checkbox } from '@mui/material';

export const boxStyle: SxProps<Theme> = {
    width: 350,
    height: 'auto',
    backgroundColor: 'rgba(245, 103, 52, 0.6)',
    paddingBottom: 1,
};

export const headerBoxStyle: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 2,
};

export const navLinkStyle: CSSProperties = {
    color: 'white',
};

export const iconStyle: SxProps<Theme> = {
    cursor: 'pointer',
};

export const textStyle: SxProps<Theme> = {
    fontSize: 14,
    color: 'white',
};

export const formBoxStyle: SxProps<Theme> = {
    backgroundColor: '#eef2f5',
    padding: 2,
};

export const fieldStyle: SxProps<Theme> = {
    marginBottom: 1,
};

export const actionBoxStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2,
};

export const buttonStyle: SxProps<Theme> = {
    backgroundColor: '#f58e3f',
    borderRadius: 2,
    ':hover': {
        backgroundColor: '#d47606',
        opacity: 0.8,
    },
    marginLeft: 2,
};

export const CustomCheckbox = styled(Checkbox)({
    color: '#d47606',
    '&.Mui-checked': {
        color: '#d47606',
    },
});

export const microsoftButton: SxProps<Theme> = {
    borderRadius: 2,
    padding: 1,
    width: '100%',
    ':hover': {
        opacity: 0.8,
    },
    marginTop: 5
}
