export const stationLabel = [
    {
        id: 0,
        label: 'Station',
        value: 'stationId'
    },
    {
        id: 1,
        label: 'Parameter',
        value: 'parameterName'
    },
    {
        id: 2,
        label: 'Longitude',
        value: 'longitude'
    },
    {
        id: 3,
        label: 'Latitude',
        value: 'latitude'
    },
    {
        id: 4,
        label: 'First date',
        value: 'firstUpdatedDateTime'
    },
    {
        id: 5,
        label: 'Last date',
        value: 'lastUpdatedDateTime'
    },
    {
        id: 6,
        label: 'Latest value',
        value: 'latestValue'
    },
]