// hooks/useDraggable.js
import { useState } from 'react';

const useDraggable = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const trackPos = (data: { x: any; y: any; }) => {
        setPosition({ x: data.x, y: data.y });
    };

    return { position, trackPos };
};
export default useDraggable;
