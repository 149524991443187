import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Text from '../../common/text/Text';
import ButtonWithIcon from '../../common/button/Button';
import { Download } from '@mui/icons-material';

const UploadHeader = ({ title, templateDownload }: any) => {
    const isSmallScreen = useMediaQuery('(min-width: 999px)')
    return (
        <Box sx={{ width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Text variant='caption' sx={{ color: '#F65E27', fontSize: isSmallScreen ? 14 : 18, fontWeight: 500 }}>{title}</Text>
            <ButtonWithIcon
                variant='outlined'
                icon={<Download />}
                onClick={templateDownload}
                text='Download Template'
                sx={{
                    color: '#f58e3f',
                    borderColor: '#f58e3f',
                    borderRadius: 2,
                    fontSize: isSmallScreen ? '14px' : '11px',
                    ':hover': {
                        color: '#fff',
                        borderColor: '#f58e3f',
                        backgroundColor: '#f58e3f',
                        opacity: 0.8
                    },
                }}
            />
        </Box>
    );
};

export default UploadHeader;
