import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import TimeseriesPanel from './TimeseriesPanel';
import TimeseriesTab from './TimeseriesTab';
import { useAuth } from '../../../context/auth-context/AuthContext';

export type Tab = {
    label: string;
    components: JSX.Element[];
    roles: string[]
}

export type TimeseriesMenuProps = {}

export type TabTimeseriesProps = {
    tabs: Tab[];
    addComponent: (tabIndex: number) => void;
    removeComponent: (tabIndex: number, componentIndex: number) => void;
}

const TimeseriesMenu: React.FC<TimeseriesMenuProps> = () => {

    const { user } = useAuth();
    const [tabs, setTabs] = useState<Tab[]>([]);

    useEffect(() => {
        
        const userRoles = user?.roles;

        const timeseriesTabs = [
            {
                label: 'Observations',
                components: [
                    <TimeseriesPanel
                        key="initialObservation"
                        panelType="Observation"
                    />
                ],
                roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
            },
            {
                label: 'Monthly Statistic',
                components: [
                    <TimeseriesPanel
                        key="initialMonthly"
                        panelType="Monthly"
                    />
                ],
                roles: ["SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
            },
        ];

        const filteredTabs = timeseriesTabs.filter(tab =>
            tab.roles.some(role => Array.isArray(userRoles) ? userRoles.includes(role) : userRoles === role)
        );

        setTabs(filteredTabs.map(tab => ({
            label: tab.label,
            components: tab.components,
            roles: tab.roles
        })));

    }, [user]);

    const addComponent = (tabIndex: number): void => {
        const newTabs = [...tabs];
        if (tabIndex === 0) {
            newTabs[tabIndex].components.push(<TimeseriesPanel key={`observation-${newTabs[tabIndex].components.length}`} panelType="Observation" />);
        } else if (tabIndex === 1) {
            newTabs[tabIndex].components.push(<TimeseriesPanel key={`monthly-${newTabs[tabIndex].components.length}`} panelType="Monthly" />);
        }
        setTabs(newTabs);
    };

    const removeComponent = (tabIndex: number, componentIndex: number) => {
        const tabsCopy = [...tabs];
        tabsCopy[tabIndex].components.splice(componentIndex, 1);
        setTabs(tabsCopy);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: 'calc(100vh - 60px)',
                boxSizing: 'border-box',
                zIndex: 2,
                backgroundColor: 'white',
            }}
        >
            <TimeseriesTab tabs={tabs} addComponent={addComponent} removeComponent={removeComponent} />
        </Box>
    );
}

export default TimeseriesMenu;
