import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { Box } from '@mui/material';
import { disclaimerText } from './DisclaimerLib';
import Text from '../../common/text/Text';
import ButtonWithIcon from '../../common/button/Button';

const DisclaimerPopup = () => {
    const { showDisclaimer, setShowDisclaimer } = useAuth()
    return (
        <Dialog
            open={showDisclaimer}
            onClose={() => setShowDisclaimer(false)}
            PaperProps={{
                sx: {
                    padding: '20px',
                    width: '800px',
                }
            }}
        >
            <DialogTitle
                id="disclaimer title"
                sx={{
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 700,
                    color: '#000'
                }}
            >
                DISCLAIMER
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {
                        disclaimerText.map(item => (
                            <Box key={item.id}>
                                <Text
                                    variant='caption'
                                    sx={{
                                        fontSize: 14,
                                        color: '#0d3549',
                                        fontWeight: 600,
                                        display: 'block'
                                    }}
                                >
                                    {item.text}
                                </Text>
                                <Text
                                    variant='caption'
                                    sx={{
                                        fontSize: 12,
                                        color: '#000',
                                        display: 'block',
                                        marginBottom: 2
                                    }}
                                >
                                    {item.subText}
                                </Text>
                            </Box>
                        ))
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonWithIcon
                    variant='outlined'
                    icon={null}
                    onClick={() => setShowDisclaimer(false)}
                    text='I Agree'
                    sx={{
                        color: '#fff',
                        borderColor: '#f58e3f',
                        backgroundColor: '#f58e3f',
                        borderRadius: 2,
                        textTransform: 'capitalize',
                        ':hover': {
                            color: '#fff',
                            borderColor: '#f58e3f',
                            backgroundColor: '#f58e3f',
                            opacity: 0.8
                        },
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default DisclaimerPopup