import React, { useState, useEffect } from 'react';
import {
    Box, Table, TableContainer, TablePagination, Paper, useMediaQuery, IconButton, TableCell,
    TableHead, TableRow, Tooltip, Typography, TableBody, Popover, TextField, Button, Select, MenuItem
} from '@mui/material';
import ButtonWithIcon from '../../common/button/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    DataRow,
    filterEvents,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickCancel,
    handleClickEdit,
    handleEditableChange,
    handleFilterApply,
    handleFilterClick,
    handleFilterClose,
    handleSave,
    handleSort,
    paginateEvents,
    SortConfig,
    sortEvents
} from '../../../utils/EventUtils';
import CreateEvent from './CreateEvent';
import { buttonStyle, containerStyle, rowStyle } from './Style';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useEventView } from '../../../context/event-context/EventContext';
import { ArrowDropDown, ArrowDropUp, Cancel, Edit, FilterList, Save } from '@mui/icons-material';
import { formatDate } from '../../../utils/Utils';
import { eventLabel } from './EventData';
import TableFilter from '../../feature/filter/TableFilter';
import InputFieldRenderer from './InputFieldRenderer';

const EventContent: React.FC = () => {
    const {
        events,
        setEvents,
        status,
        eventType,
        assignedToList,
        eventFilter,
        saveEvent,
        fetchEvents,
        fetchAssignedToList,
        fetchStatus,
        fetchType
    } = useEventView();
    const { baseUrl, user } = useAuth();

    const isSmallScreen = useMediaQuery('(min-width: 999px)');
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState<string>('stationId');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterLabel, setFilterLabel] = useState<string | null>(null)
    const [filterField, setFilterField] = useState<string | number | Date | null>(null);
    const [filterValue, setFilterValue] = useState<string | number | Date>('');
    const [filters, setFilters] = useState<{ [key: string]: string | number | Date }>({});

    const [editingRow, setEditingRow] = useState<number | null>(null);
    const [editableValues, setEditableValues] = useState<{ [key: number]: Partial<DataRow> }>({});

    const filteredEvents = filterEvents(events, filters);
    const sortedData = sortEvents(filteredEvents, eventFilter);
    const paginatedData = paginateEvents(sortedData, page, rowsPerPage);

    useEffect(() => {
        const fetchData = async () => {
            fetchEvents();
            if (assignedToList.length === 0) {
                fetchAssignedToList();
            }
            if (status.length === 0) {
                fetchStatus();
            }
            if (eventType.length === 0) {
                fetchType();
            }
        };

        if (baseUrl && user?.token) {
            fetchData();
        }
    }, [baseUrl, user?.token]);

    return (
        <Box sx={containerStyle}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding={1} marginTop={1} marginBottom={1}>
                <Box position="relative" sx={{ width: '100%', textAlign: 'left' }}>
                    <ButtonWithIcon
                        variant='outlined'
                        icon={<AddIcon />}
                        onClick={() => setShowCreateForm(true)}
                        text='Create New Event'
                        sx={buttonStyle(isSmallScreen)}
                    />
                    {showCreateForm && <CreateEvent onClose={() => setShowCreateForm(false)} />}
                </Box>
                <TableFilter />
            </Box>
            <Box sx={{ padding: 1 }}>
                <TableContainer component={Paper} sx={{ maxHeight: '72vh' }}>
                    <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead>
                            <TableRow>
                                {
                                    eventLabel.map(item => (
                                        <TableCell
                                            key={item.label}
                                            align="left"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => setOrderBy(item.value)}
                                        >
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontSize: isSmallScreen ? 14 : 12,
                                                display: 'inline-block'
                                            }}>
                                                {item.label}
                                            </Typography>
                                            {(orderBy === item.value) ? (
                                                sortConfig.direction === 'ascending' ? (
                                                    <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="sorting data point"
                                                            aria-controls="sorting data point"
                                                            aria-haspopup="true"
                                                            color="inherit"
                                                            onClick={() => handleSort(item.value, sortConfig, events, setSortConfig, setOrderBy, setEvents)}
                                                        >
                                                            <ArrowDropDown sx={{ fontSize: 16, display: 'inline-block' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="sorting data point"
                                                            aria-controls="sorting data point"
                                                            aria-haspopup="true"
                                                            color="inherit"
                                                            onClick={() => handleSort(item.value, sortConfig, events, setSortConfig, setOrderBy, setEvents)}
                                                        >
                                                            <ArrowDropUp sx={{ fontSize: 16, display: 'inline-block' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            ) : null}
                                            {(orderBy === item.value) ? (
                                                <Tooltip title={`Filter by ${item.label}`}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="filter data point"
                                                        aria-controls="filter data point"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        onClick={(e) => handleFilterClick(e, item, setAnchorEl, setFilterLabel, setFilterField, setFilterValue)}
                                                    >
                                                        <FilterList sx={{ fontSize: 16, display: 'inline-block' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : null}
                                        </TableCell>
                                    ))
                                }
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((data: any, index: number) => {
                                    const typeLabelConfig = eventLabel.find(event => event.value === 'type');
                                    const statusLabelConfig = eventLabel.find(event => event.value === 'status');

                                    const formattedType = typeLabelConfig?.formatter ? typeLabelConfig.formatter(data.type) : data.type;
                                    const formattedStatus = statusLabelConfig?.formatter ? statusLabelConfig.formatter(data.status) : data.status;

                                    const isEditing = editingRow === data.id;

                                    return (
                                        <TableRow key={`${data.id}-${index}`}>
                                            <TableCell component="th" scope="row" sx={rowStyle(isSmallScreen)}>{data.id}</TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>{formattedType}</TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>{data.description}</TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {data.startedDateTime ? formatDate(data.startedDateTime, true) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {data.completedDateTime ? formatDate(data.completedDateTime, true) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>{data.createdBy}</TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {data.reportedDateTime ? formatDate(data.reportedDateTime, false) : ''}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {isEditing ? (
                                                    <Select
                                                        fullWidth
                                                        value={editableValues[data.id]?.status ?? data.status}
                                                        sx={{ height: 30, fontSize: 12 }}
                                                        onChange={(e) => handleEditableChange(data.id, 'status', parseInt(e.target.value as string), assignedToList, setEditableValues)}
                                                    >
                                                        <MenuItem value="0">New</MenuItem>
                                                        <MenuItem value="1">In Progress</MenuItem>
                                                        <MenuItem value="2">Completed</MenuItem>
                                                        <MenuItem value="3">On Hold</MenuItem>
                                                    </Select>
                                                ) : (
                                                    formattedStatus
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {isEditing ? (
                                                    <Select
                                                        fullWidth
                                                        value={editableValues[data.id]?.assignedUserId || data.assignedUserId}
                                                        sx={{ height: 30, fontSize: 12 }}
                                                        onChange={(e) => handleEditableChange(data.id, 'assignedTo', e.target.value as string, assignedToList, setEditableValues)}
                                                    >
                                                        {assignedToList.map((item, index) => {
                                                            const displayAssignName = `${item.firstName} ${item.lastName}`;
                                                            return (
                                                                <MenuItem
                                                                    value={item.id}
                                                                    key={index}
                                                                >
                                                                    {displayAssignName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                ) : (
                                                    data.assignedTo
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={rowStyle(isSmallScreen)}>
                                                {isEditing ? (
                                                    <TextField
                                                        fullWidth
                                                        value={editableValues[data.id]?.remarks}
                                                        onChange={(e) => handleEditableChange(data.id, 'remarks', e.target.value, assignedToList, setEditableValues)}
                                                        sx={{
                                                            fontSize: 12,
                                                            '& .MuiInputBase-root': {
                                                                height: 30,
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                fontSize: 12,
                                                                padding: '6px 8px',
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    data.remarks
                                                )}
                                            </TableCell>
                                            <TableCell sx={rowStyle(isSmallScreen)}>
                                                {isEditing ? (
                                                    <React.Fragment>
                                                        <IconButton onClick={() => handleSave(data.id, events, editableValues, saveEvent, setEditingRow, setEditableValues)}>
                                                            <Save />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleClickCancel(setEditingRow)}>
                                                            <Cancel />
                                                        </IconButton>
                                                    </React.Fragment>
                                                ) : (
                                                    <IconButton onClick={() => handleClickEdit(data.id, setEditingRow, setEditableValues, events)}>
                                                        <Edit />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={eventLabel.length + 1} align="center">
                                        <Typography>No data available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filteredEvents.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage(setPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage(setRowsPerPage, setPage)}
                />
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => handleFilterClose(setAnchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={1}>
                    <InputFieldRenderer
                        field={filterField!}
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                        filterLabel={filterLabel}
                    />
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button variant="contained" onClick={() => handleFilterApply(filterField, filterValue, setFilters, () => handleFilterClose(setAnchorEl))}>Apply</Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default EventContent;
