import React, { Fragment } from 'react'
import LandingPageBanner from '../../components/layout/landing-page/LandingPageBanner'
import LandingPageMonitoring from '../../components/layout/landing-page/LandingPageMonitoring'
import LandingPageDescription from '../../components/layout/landing-page/LandingPageDescription'
import LandingPageBottom from '../../components/layout/landing-page/LandingPageBottom'
import Footer from '../../components/layout/footer/Footer'
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import { useAuth } from '../../context/auth-context/AuthContext'

const LandingPage = () => {

  const { snackbarMessage, openNotification, setOpenNotification } = useAuth()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Fragment>
      <LandingPageBanner />
      <LandingPageMonitoring />
      <LandingPageDescription />
      <LandingPageBottom />
      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openNotification}
      >
        <Dialog
          fullScreen={fullScreen}
          open={openNotification}
          onClose={() => {
            setOpenNotification(false);
          }}
        >
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            {snackbarMessage}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenNotification(false);
            }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>
    </Fragment>
  )
}

export default LandingPage