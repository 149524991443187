import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import ButtonWithIcon from '../../common/button/Button';
import { Upload, ArrowBack, ArrowUpward } from '@mui/icons-material';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import UploadDocument from './UploadDocument';
import { buttonStyle, disabledButtonStyle, containerDocumentActionStyle } from './Style';

const DocumentAction: React.FC = () => {

    const { goBack, goUp, canGoBack, canGoUp } = useDocumentView();
    const [showUploadForm, setShowUploadForm] = React.useState(false);
    const isSmallScreen = useMediaQuery('(max-width: 799px)')

    const buttons = [
        {
            icon: <Upload />,
            onClick: () => setShowUploadForm(true),
            text: 'Upload Document',
            sx: buttonStyle('#f58e3f', isSmallScreen),
            disabled: false,
        },
        {
            icon: <ArrowBack />,
            onClick: goBack,
            text: 'Back',
            sx: disabledButtonStyle('#f58e3f', isSmallScreen),
            disabled: !canGoBack,
        },
        {
            icon: <ArrowUpward />,
            onClick: goUp,
            text: 'Up',
            sx: disabledButtonStyle('#f58e3f', isSmallScreen),
            disabled: !canGoUp,
        },
    ];

    return (
        <Box sx={containerDocumentActionStyle}>
            {buttons.map((button, index) => (
                <ButtonWithIcon
                    key={index}
                    variant='outlined'
                    icon={button.icon}
                    onClick={button.onClick}
                    text={button.text}
                    sx={button.sx}
                    disabled={button.disabled}
                />
            ))}
            {showUploadForm && <UploadDocument onClose={() => setShowUploadForm(false)} />}
        </Box>
    );
};

export default DocumentAction;
