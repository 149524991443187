import React from 'react';
import { Box, Snackbar, Alert, useMediaQuery } from '@mui/material';
import fishFarm from '../../../asset/Images/fish-farm.png';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useLocation } from 'react-router-dom';

interface ContentWrapperProps {
    children: React.ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
    const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth();
    const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';
    const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');
    const location = useLocation()
    const isUploadPage = location.pathname === '/upload'

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%), url(${fishFarm})`,
                backgroundPosition: 'top left',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: 0,
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                padding: isTabletOrDesktop ? '100px 30px 30px 30px' : '90px 20px 20px 20px',
            }}
        >
            {children}
            {
                !isUploadPage && (
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={5000}
                        onClose={() => setSnackbarOpen(false)}
                    >
                        <Alert
                            onClose={() => setSnackbarOpen(false)}
                            severity={alertSeverity}
                            variant="filled"
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                )
            }
        </Box>
    );
};

export default ContentWrapper;
