import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import sensorImage from '../../../asset/Images/sensor.png';
import Text from '../../common/text/Text';

const LandingPageDescription = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isSmallScreen || isMediumScreen ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: isSmallScreen || isMediumScreen ? 5 : 20,
                paddingRight: isSmallScreen || isMediumScreen ? 5 : 20,
                marginTop: -40,
                textAlign: isSmallScreen || isMediumScreen ? 'center' : 'left',
                gap: 2,
            }}
        >
            <Box flex={1} marginBottom={isSmallScreen ? 2 : 0} textAlign={'right'}>
                <img
                    src={sensorImage}
                    alt='sensor'
                    style={{
                        width: isSmallScreen ? '250px' : isMediumScreen ? '300px' : '350px',
                        height: 'auto',
                    }}
                />
            </Box>
            <Box flex={2} textAlign={'left'}>
                <Text
                    variant={"body1"}
                    sx={{
                        color: 'black',
                        fontSize: isSmallScreen ? '12px' : isMediumScreen ? '16px' : '18px',
                    }}
                >
                    Our online water quality monitoring stations, track crucial parameters like Dissolved Oxygen, Salinity, Conductivity, Temperature, pH, Chlorophyll, and Turbidity in near real-time. These metrics provide insights into the health of the aquatic environment, aiding informed decision-making for more robust management of the farming environment.
                </Text>
            </Box>
        </Box>
    );
};

export default LandingPageDescription;
