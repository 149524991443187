import React from 'react';
import { Box } from '@mui/material';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Microsoft, ArrowBackIos } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import Text from '../../common/text/Text';
import { TextField } from 'formik-mui';
import ButtonWithIcon from '../../common/button/Button';
import { InitialUser, useAuth } from '../../../context/auth-context/AuthContext';
import useLogin from '../../../hooks/useLogin';
import {
    boxStyle,
    headerBoxStyle,
    navLinkStyle,
    iconStyle,
    textStyle,
    formBoxStyle,
    fieldStyle,
    actionBoxStyle,
    buttonStyle,
    CustomCheckbox,
    microsoftButton
} from './Style';

const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

const LoginForm: React.FC = () => {

    const {
        initialUser,
        setInitialUser,
        loading,
        handleOidcLogin,
    } = useAuth();
    const { onSubmit } = useLogin();

    return (
        <Box sx={boxStyle}>
            <Box sx={headerBoxStyle}>
                <NavLink to="/" style={navLinkStyle}>
                    <ArrowBackIos sx={iconStyle} />
                </NavLink>
                <Text variant='caption' sx={textStyle}>Sign In</Text>
            </Box>
            <Formik
                initialValues={initialUser}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers: FormikHelpers<InitialUser>) => {
                    onSubmit(values, formikHelpers);
                }}
                enableReinitialize
            >
                {({ handleChange }) => (
                    <Form>
                        <Box sx={formBoxStyle}>
                            <Field
                                component={TextField}
                                name="username"
                                label="Username"
                                variant="filled"
                                fullWidth
                                sx={fieldStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setInitialUser((prev: InitialUser) => ({
                                        ...prev,
                                        username: e.target.value,
                                    }));
                                }}
                            />
                            <Field
                                component={TextField}
                                name="password"
                                label="Password"
                                variant="filled"
                                fullWidth
                                type="password"
                                sx={fieldStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setInitialUser((prev: InitialUser) => ({
                                        ...prev,
                                        password: e.target.value,
                                    }));
                                }}
                            />
                            <Box sx={actionBoxStyle}>
                                <Box>
                                    <Field
                                        type="checkbox"
                                        name="rememberMe"
                                        as={CustomCheckbox}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChange(e);
                                            setInitialUser((prev: InitialUser) => ({
                                                ...prev,
                                                rememberMe: e.target.checked,
                                            }));
                                        }}
                                    />
                                    <Text variant='caption'>Remember Me</Text>
                                </Box>
                                <ButtonWithIcon
                                    variant='contained'
                                    type='submit'
                                    icon={null}
                                    text='Sign In'
                                    disabled={loading}
                                    sx={buttonStyle}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <ButtonWithIcon
                                    variant='contained'
                                    onClick={handleOidcLogin}
                                    icon={<Microsoft />}
                                    text='Microsoft Account'
                                    sx={microsoftButton}
                                />
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

export default LoginForm;
