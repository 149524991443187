import { useUploadView } from "../../../context/upload-context/UploadContext";
import { additionalFileAccept, fileAccept } from "./UploadMenu";
import UploadWrapper from "./UploadWrapper";

const SampleInfoUpload: React.FC = () => {
    
    const { loading, downloadTemplateSampleInfo, uploadSampleInfo, uploadAdditionalSampleInfo } = useUploadView();

    const handleUploadFile = async (file: File): Promise<void> => {
        await uploadSampleInfo(file);
    };

    const handleUploadAdditionalFiles = async (files: File[]): Promise<void> => {
        await uploadAdditionalSampleInfo(files);
    };

    return (
        <UploadWrapper
            title="Sample Info Upload"
            templateDownload={downloadTemplateSampleInfo}
            uploadFile={handleUploadFile}
            uploadAdditionalFile={handleUploadAdditionalFiles}
            fileAccept={fileAccept}
            additionalFileAccept={additionalFileAccept}
            anotherButtonText="Upload Another Sample Info"
            loading={loading}
        />
    );
};

export default SampleInfoUpload;
