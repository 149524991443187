import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Text from '../../common/text/Text';
import useSendMessage from '../../../hooks/useSendMessage';
import { useContact } from '../../../context/contact-context/contactContext';
import useRecaptchaV3 from '../../../hooks/useRecaptchaV3';
import { messageValidationSchema, SendMessageFormValues } from '../../../pages/contact-us-page/ContactUsLib';
import SendMessageForm from './SendMessageForm';
import { FormikHelpers } from 'formik';
import { useAuth } from '../../../context/auth-context/AuthContext';

const SendMessage: React.FC = () => {

    const { baseUrl, user } = useAuth()
    const { fetchCategories, categories } = useContact();
    const { onSubmit } = useSendMessage();
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcahxoqAAAAAMK9D-RGvLGEJmTCHT7TXsID9epw';
    const { token, executeRecaptcha } = useRecaptchaV3(recaptchaSiteKey);

    useEffect(() => {
        if (baseUrl && user?.token && categories.length === 0) {
            fetchCategories();
        }
    }, [baseUrl, user?.token]);

    const handleSubmit = async (values: SendMessageFormValues, actions: FormikHelpers<SendMessageFormValues>) => {
        await executeRecaptcha();
        if (token) {
            await onSubmit({ ...values, recaptcha: token }, actions);
        } else {
            actions.setStatus({ error: 'Failed to get reCAPTCHA token. Please try again later.' });
            actions.setSubmitting(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flex: 1,
                height: 'auto',
                backgroundColor: 'white',
                padding: 5,
            }}
        >
            <Text
                variant='h5'
                sx={{
                    color: '#F88E3A',
                    fontWeight: '500',
                    fontSize: '18px',
                    marginBottom: 2,
                }}
            >
                Send Us A Message
            </Text>
            <SendMessageForm
                categories={categories}
                initialValues={{ name: '', email: '', subject: '', category: '', enquiry: '' }}
                validationSchema={messageValidationSchema}
                onSubmit={handleSubmit}
                resetRecaptcha={executeRecaptcha}
                reCaptChaSiteKey={recaptchaSiteKey}
            />
        </Box>
    );
};

export default SendMessage;
