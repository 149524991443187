import React, { useState } from 'react';
import { Box, Tab, Tabs as MuiTabs, styled, useTheme, useMediaQuery } from '@mui/material';
import LayerCollapse from '../../feature/layer-collapse/LayerCollapse';
import Measurement from '../../feature/measurement/Measurement';

const CustomTabContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .MuiTabs-flexContainer': {
        marginRight: 20,
        marginTop: 5,
    },
    '& .MuiTabs-root': {
        overflow: 'hidden',
        marginBottom: 0,
        borderBottom: '4px solid #CFDBE2',
        display: 'flex',
        width: '100%',
    },
    '& .MuiTab-root': {
        marginTop: 5,
        marginBottom: 0,
        textTransform: 'capitalize',
        minWidth: 'auto',
        flex: 1,
    },
    '& .MuiTab-root.Mui-selected': {
        color: '#555555',
        fontWeight: 700,
        backgroundColor: '#CFDBE2',
        margin: 5,
        marginBottom: 0,
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LayerList = () => {

    const [value, setValue] = useState<number>(0);
    const theme = useTheme()
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const getWidth = (): number | string => {
        switch (true) {
            case isXSmallScreen:
                return '80vw'
            case isSmallScreen:
                return 300
            case isMediumScreen:
                return 350
            case isLargeScreen:
                return 400
            default:
                return 450
        }
    }

    const mapViewTabs = [
        {
            label: 'Layers',
            components: [
                <LayerCollapse />
            ],
            roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
        },
        {
            label: 'Measurements',
            components: [
                <Measurement />
            ],
            roles: ["FishFarmer", "SFAAgency", "SFAGeneral", "Editor", "Administrator", "DHIAdmin", "SuperAdministrator"],
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: getWidth(),
            }}
        >
            <CustomTabContainer>
                <MuiTabs value={value} onChange={handleChange} aria-label="map view tab">
                    {mapViewTabs.map((tab, index) => (
                        <Tab label={tab.label} {...a11yProps(index)} key={index} />
                    ))}
                </MuiTabs>
            </CustomTabContainer>
            <Box>
                {mapViewTabs[value]?.components.map((Component, index) => (
                    <Box
                        key={index}
                        marginBottom={20}
                        sx={{
                            overflow: 'hidden',
                            marginTop: index > 0 ? -10 : 0,
                        }}
                    >
                        {Component}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default LayerList;
