import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

interface ButtonProps {
    variant: 'text' | 'outlined' | 'contained';
    icon?: React.ReactNode;
    onClick?: () => void;
    text: string;
    disabled?: boolean;
    sx?: SxProps<Theme>;
    type?: "button" | "submit" | "reset";
}

const ButtonWithIcon = ({ variant, icon, onClick, text, type = 'button', disabled, sx }: ButtonProps) => (
    <Button
        variant={variant}
        type={type}
        startIcon={icon}
        onClick={onClick}
        disabled={disabled}
        sx={sx}
    >
        {text}
    </Button>
);

export default ButtonWithIcon;
