import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import DocumentNavigation from './DocumentNavigation';
import DocumentAction from './DocumentAction';
import DocumentListView from './DocumentListView';
import { containerDocumentContentStyle, headerStyle, listViewStyle, logoStyle } from './Style';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import DocumentBreadcrumb from './DocumentBreadcrumb';
import { useAuth } from '../../../context/auth-context/AuthContext';

const DocumentContent: React.FC = () => {

    const { baseUrl, user } = useAuth()
    const { fetchDocumentList, path } = useDocumentView();

    useEffect(() => {
        if (baseUrl && user?.token) {
            fetchDocumentList()
        }
    }, [baseUrl, user?.token, path])

    return (
        <Box sx={containerDocumentContentStyle}>
            <Box sx={headerStyle}>
                <DocumentNavigation />
                <DocumentAction />
            </Box>
            <Box sx={{ mt: 2 }}>
                <DocumentBreadcrumb />
            </Box>
            <Box sx={listViewStyle}>
                <DocumentListView />
            </Box>
        </Box>
    );
};

export default DocumentContent;
