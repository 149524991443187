import { Theme } from '@mui/material/styles'

export const createStyles = (
    theme: Theme,
    isXSmallScreen: boolean,
    isSmallScreen: boolean,
    isMediumScreen: boolean,
    isLargeScreen: boolean
) => ({
    container: {
        width: isXSmallScreen
            ? '80vw'
            : isSmallScreen
                ? '300px'
                : isMediumScreen
                    ? '350px'
                    : isLargeScreen
                        ? '400px'
                        : '450px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 1,
    },
    dataTypeLabel: {
        textAlign: 'left',
        fontSize: '12px',
    },
    dataTypeForm: {
        width: 200,
        textAlign: 'left',
    },
    dataTypeSelect: {
        color: '#09334B',
        fontSize: 12,
        fontWeight: 'bold',
        height: 30,
    },
    dataTypeOptions: {
        color: '#09334B',
        fontSize: 11,
        fontWeight: 'bold',
    },
    dataPointContainer: {
        width: '100%',
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
        marginTop: 2,
        '&::-webkit-scrollbar': {
            width: '8px',
            cursor: 'pointer',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            cursor: 'pointer',
        },
    },
    dataPointParameterTable: {
        textAlign: 'left',
        marginBottom: 3,
        width: '100%',
        boxSizing: 'border-box',
    },
    dataPointParameterLabel: {
        color: '#555555',
        fontWeight: 'bold',
    },
    headerTableRow: {
        backgroundColor: '#CFDBE2',
    },
    headerTableCell: {
        fontSize: '12px',
        color: '#555555',
        fontWeight: 'bold',
        padding: '10px',
        width: 120,
    },
    bodyLabel: {
        fontSize: '11px',
        display: 'inline-block',
    },
    bodyTableRowCell: {
        padding: '5px',
    },
    chartIcon: {
        width: '10px',
        display: 'inline-block',
    },
})