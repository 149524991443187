import { Box } from '@mui/material'
import React from 'react'
import { CursorPositionProps } from '../../../pages/map-view-page/MapViewLib';

const MapCursorPosition = ({ latitude, longitude }: CursorPositionProps) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                left: 110,
                bottom: 5,
                paddingLeft: 2,
                paddingRight: 2,
                fontSize: 10,
                backgroundColor: 'rgba(255, 2255, 255, 0.9)',
                transform: 'translateX(-50%)',
                border: '2px solid black',
                borderTop: 'none',
                zIndex: 2
            }}
        >
            Latitude : {latitude ? latitude.toFixed(4) : '0'},
            Longitude : {longitude ? longitude.toFixed(4) : '0'}
        </Box>
    )
}

export default MapCursorPosition