import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { getMenuBarData } from './MenuData';
import { menuBarStyle, navLinkStyle } from './Style';
import useMediaQuery from '@mui/material/useMediaQuery';

const MenuBar = () => {

    const { user } = useAuth();
    const location = useLocation();
    const isTabletOrDesktop = useMediaQuery('(min-width: 1300px)');

    const menuBarData = getMenuBarData(user);

    return (
        <Box sx={() => menuBarStyle(!isTabletOrDesktop)}>
            {menuBarData.map(res => (
                <NavLink
                    key={res.id}
                    to={res.link}
                    style={({ isActive }) => navLinkStyle(isActive, location.pathname === '/')}
                >
                    {res.title}
                </NavLink>
            ))}
        </Box>
    );
};

export default MenuBar;
