import React, { createContext, useContext, ReactNode, useState, useEffect, useRef } from 'react';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';

export interface InitialUser {
  username: string;
  password: string;
  rememberMe: boolean
}

export interface JwtToken extends JwtPayload {
  preferred_username: string;
  name: string;
  roles: string[];
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  token: string
}

export interface User {
  name: string
  email: string
  roles: string[]
  nbf: number
  expired: number
  iat: number
  token: string
  iss: string
  initial?: string
  preferred_username?: string
}

interface AuthContextType {
  baseUrl: string;
  initialUser: InitialUser;
  setInitialUser: (val: any) => void;
  user: any | null;
  setUser: (val: any) => void;
  snackbarOpen: boolean;
  setSnackbarOpen: (val: boolean) => void;
  snackbarMessage: string;
  setSnackbarMessage: (val: string) => void;
  variant: string;
  setVariant: (val: string) => void;
  showDisclaimer: boolean;
  setShowDisclaimer: (val: boolean) => void;
  handleImsLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleOidcLogin: () => void;
  logout: () => void;
  isTokenExpired: () => boolean;
  handleRefreshToken: () => Promise<void>;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: (val: boolean) => void;
  loading: boolean;
  openNotification: boolean;
  setOpenNotification: (val: boolean) => void;
  setRefreshTokenTimeout: (val: number) => void;
  createNewUserData: (decodedToken: JwtToken, token: string) => User;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  const [initialUser, setInitialUser] = useState<InitialUser>({ username: '', password: '', rememberMe: false })
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [variant, setVariant] = useState<string>('success'); // 'error', 'warning', 'info', 'success'
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [isMobileMenuActive, setIsMobileMenuActive] = useState<boolean>(false);
  const tokenRefreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const { logout: oidcLogout, login: oidcLogin } = useOidc();
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const { accessToken } = useOidcAccessToken();
  const isLocalLogin = accessToken !== null && accessToken !== undefined
  const [storedToken, setStoredToken] = useState<string | null>(null)

  const navigate = useNavigate()
  const environment = window.location.origin;
  const isProduction = environment === 'https://asn.infoatsea.com';

  useEffect(() => {
    if (isProduction) {
      setBaseUrl(process.env.REACT_APP_BASE_URL_PROD || '');
    } else {
      setBaseUrl(process.env.REACT_APP_BASE_URL_DEV || '');
    }

    const rememberMe = !!localStorage.getItem('token');
    const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode<JwtToken>(token);
      let userRole = decodedToken.roles as any;
      if (
        !userRole ||
        (typeof userRole === 'string' && userRole.trim() === "") ||
        (Array.isArray(userRole) && userRole.length === 0)
      ) {

        clearStorage()

        setSnackbarMessage('Invalid system user');
        setOpenNotification(true);

        setTimeout(() => {
          navigate('/');
        }, 2000);

        return;
      }
      if (Date.now() / 1000 < decodedToken.exp) {
        const newUserData = createNewUserData(decodedToken, token)
        setUser(newUserData)
        setRefreshTokenTimeout(decodedToken.exp)
      } else {
        clearStorage()
      }
    }
  }, []);

  const clearStorage = () => {
    sessionStorage.clear()
    localStorage.clear()
  }

  const saveTokenToStorage = (token: string, refreshToken: string, rememberMe: boolean) => {
    setStoredToken(token)
    if (rememberMe) {
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
    } else {
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('refreshToken', refreshToken)
    }
  }

  const decodeToken = (token: string): JwtToken => {
    return jwtDecode<JwtToken>(token)
  }

  const handleLoginError = (message: string) => {
    setSnackbarMessage(message)
    setVariant('error')
    setSnackbarOpen(true)
  }

  const createNewUserData = (decodedToken: JwtToken, token: string): User => {
    const { name, preferred_username, roles, nbf, exp, iat, iss } = decodedToken
    return {
      name,
      email: preferred_username,
      roles,
      nbf,
      expired: exp,
      iat,
      iss,
      token,
      initial: preferred_username?.split('@')[0],
    }
  }

  const handleImsLogin = async (username: string, password: string, rememberMe: boolean) => {
    if (!baseUrl) return
    setLoading(true)
    try {
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      })

      if (!response.ok) throw new Error('Login failed')
      const data = await response.json()
      saveTokenToStorage(data.accessToken, data.refreshToken, rememberMe)
    } catch (error) {
      handleLoginError(`${error}`)
    } finally {
      setLoading(false)
    }
  };

  const handleOidcLogin = async () => {
    await oidcLogin()
  }

  const refreshImsLogin = async () => {
    if (!baseUrl) return

    setLoading(true)
    const rememberMe = !!localStorage.getItem('token')
    const token = rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token')
    const refreshToken = rememberMe
      ? localStorage.getItem('refreshToken')
      : sessionStorage.getItem('refreshToken')

    try {
      const response = await fetch(`${baseUrl}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ accessToken: token, refreshToken }),
      })

      if (!response.ok) throw new Error('Token refresh failed')

      const data = await response.json()
      saveTokenToStorage(data.accessToken, data.refreshToken, rememberMe)
    } catch (error) {
      clearStorage()
      setUser(null)
      navigate('/login')
    } finally {
      setLoading(false)
    }
  }

  const handleRefreshToken = async () => {
    if (!user) return

    if (isLocalLogin) {
      await handleOidcLogin()
    } else {
      try {
        await refreshImsLogin()
      } catch (error) {
        clearStorage()
        navigate('/login')
      }
    }
  }

  const logout = () => {
    if (isLocalLogin) {
      oidcLogout()
      clearStorage()
    }
    setUser(null)
    clearStorage()
  }

  const isTokenExpired = (): boolean => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
      const decodedToken = decodeToken(token)
      return Date.now() / 1000 > decodedToken.exp
    }
    return true
  }

  const setRefreshTokenTimeout = (tokenExp: number) => {
    if (tokenRefreshTimeout.current) {
      clearTimeout(tokenRefreshTimeout.current)
    }
    const bufferTime = 60 * 10
    const delay = Math.max(0, (tokenExp - Date.now() / 1000 - bufferTime) * 1000)
    tokenRefreshTimeout.current = setTimeout(handleRefreshToken, delay)
  }

  useEffect(() => {
    return () => {
      if (tokenRefreshTimeout.current) {
        clearTimeout(tokenRefreshTimeout.current)
      }
    }
  }, [])

  useEffect(() => {
    if (storedToken) {
      const decodedToken = jwtDecode<JwtToken>(storedToken)
      const userRole = decodedToken.roles as string[] | string

      if (
        !userRole ||
        (typeof userRole === 'string' && userRole.trim() === '') ||
        (Array.isArray(userRole) && userRole.length === 0)
      ) {
        clearStorage()
        setSnackbarMessage('Invalid system user')
        setOpenNotification(true)
        setTimeout(() => {
          navigate('/')
        }, 2000)
        return
      }

      if (Date.now() / 1000 < decodedToken.exp) {
        const newUserData = createNewUserData(decodedToken, storedToken)
        setUser(newUserData)
        setRefreshTokenTimeout(decodedToken.exp)
      } else {
        clearStorage()
      }
    }

    // Check accessToken from OIDC
    if (isLocalLogin && accessToken) {
      const decodedAccessToken = jwtDecode<JwtToken>(accessToken)
      const userRole = decodedAccessToken.roles as string[] | string

      if (
        !userRole ||
        (typeof userRole === 'string' && userRole.trim() === '') ||
        (Array.isArray(userRole) && userRole.length === 0)
      ) {
        clearStorage()
        setSnackbarMessage('Invalid system user')
        setOpenNotification(true)
        setTimeout(() => {
          navigate('/')
        }, 2000)
        return
      }

      const newUserData = createNewUserData(decodedAccessToken, accessToken)
      setUser(newUserData)

      // Store the accessToken if using rememberMe
      if (initialUser.rememberMe) {
        localStorage.setItem('token', accessToken)
      } else {
        sessionStorage.setItem('token', accessToken)
      }

      setRefreshTokenTimeout(decodedAccessToken.exp)
      navigate('/map-view')
    }
  }, [accessToken, storedToken])

  return (
    <AuthContext.Provider
      value={{
        handleImsLogin,
        handleOidcLogin,
        logout,
        baseUrl,
        initialUser,
        setInitialUser,
        user,
        setUser,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        variant,
        setVariant,
        showDisclaimer,
        setShowDisclaimer,
        isTokenExpired,
        handleRefreshToken,
        isMobileMenuActive,
        setIsMobileMenuActive,
        loading,
        openNotification,
        setOpenNotification,
        setRefreshTokenTimeout,
        createNewUserData,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
