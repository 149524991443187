import React from 'react'
import DocumentContent from '../../components/layout/document-content/DocumentContent'
import ContentWrapper from '../../components/layout/content-wrapper/ContentWrapper'

const DocumentPage = () => {

  return (
    <ContentWrapper>
      <DocumentContent />
    </ContentWrapper>
  )
}

export default DocumentPage