import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MapGl, { ScaleControl, MapLayerMouseEvent } from 'react-map-gl';
import { useMapView } from '../../../context/map-context/mapContext';
import {
    getValidStationIds,
    handleFullscreen,
    handleMouseMove,
    zoomIn,
    zoomOut,
} from '../../../utils/MapUtils';
import { CursorPositionProps } from '../../../pages/map-view-page/MapViewLib';
import MapCursorPosition from '../../feature/map-cursor-position/MapCursorPosition';
import MapLegend from '../../feature/map-legend/MapLegend';
import CustomPopup from '../custom-popup/CustomPopup';
import MapMarker from '../../feature/map-marker/MapMarker';
import FishFarmLayer from './FishFarmLayer';
import NavigationControls from '../../feature/navigation-control/NavigationControls';
import { useMediaQuery } from '@mui/material';
import TimeSlider from '../../feature/time-slider/TimeSlider';

const ReactMapGL = () => {
    const {
        mapRef,
        initialViewport,
        viewport,
        mapStyle,
        fishFarm,
        stations,
        popupInfo,
        visibility,
        isShowFishFarms,
        isShowTimeSlider,
        layersData,
        showMenu,
        dataType,
        setViewport,
        setPopupInfo,
        setSelectedStation,
    } = useMapView();

    const location = useLocation();
    const isSmallScreen = useMediaQuery('(min-width: 699px)');
    const mapboxAccessToken = useMemo(() => process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'pk.eyJ1IjoidHNhbGlzcm9zeWFkaSIsImEiOiJjbHF4YXM2cXMwOWswMmptaTZ5MnM4d2l1In0._672ljsVEiL51bp5b_PP7g', []);

    const [cursorPosition, setCursorPosition] = useState<CursorPositionProps>({ latitude: 0, longitude: 0 });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [hoveredFeatureName, setHoveredFeatureName] = useState<string | null>(null);

    const selectedStation = useMemo(
        () => stations.find((item: any) => item.stationId === popupInfo?.stationId),
        [stations, popupInfo]
    );

    const validStationIds = useMemo(() => getValidStationIds(layersData), [layersData]);

    const filteredStations = useMemo(
        () => stations.filter((station: any) => validStationIds.has(station.stationId) && !visibility[station.stationId]),
        [stations, validStationIds, visibility]
    );

    const onMouseMove = useCallback(
        (event: MapLayerMouseEvent) => handleMouseMove(event, mapRef, setCursorPosition),
        [mapRef]
    );

    const onZoomIn = useCallback(() => zoomIn(viewport, setViewport), [viewport, setViewport]);
    const onZoomOut = useCallback(() => zoomOut(viewport, setViewport), [viewport, setViewport]);

    const onFullscreen = useCallback(() => {
        if (mapRef.current) {
            const mapContainer = mapRef.current.getMap().getContainer();
            setIsFullScreen((prev) => !prev);
            handleFullscreen(mapContainer);
        }
    }, [mapRef]);

    const onMouseEnter = useCallback((e: MapLayerMouseEvent) => {
        if (e.features && e.features.length > 0) {
            setHoveredFeatureName(e.features[0].properties?.Name || null);
        }
    }, []);

    const onMouseLeave = useCallback(() => setHoveredFeatureName(null), []);

    const handleInitialExtent = useCallback(() => {
        if (mapRef.current?.getMap()) {
            mapRef.current.getMap().flyTo({
                center: [initialViewport.longitude, initialViewport.latitude],
                zoom: isSmallScreen ? 10.7 : 9.5,
                essential: true,
            });
        }
    }, [initialViewport, mapRef, isSmallScreen]);

    const groupStationsByCoordinates = useCallback((stations: any[]) => {
        return stations.reduce((grouped: { [key: string]: any[] }, station) => {
            const key = `${station.latitude},${station.longitude}`;
            if (!grouped[key]) {
                grouped[key] = [];
            }
            grouped[key].push(station);
            return grouped;
        }, {});
    }, []);

    const groupedStations = useMemo(() => Object.values(groupStationsByCoordinates(filteredStations)), [
        filteredStations,
        groupStationsByCoordinates,
    ]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.getMap().resize();
        }
    }, [mapRef, showMenu]);

    return (
        <MapGl
            ref={mapRef}
            {...viewport}
            mapboxAccessToken={mapboxAccessToken}
            mapStyle={mapStyle}
            onMove={(evt) => setViewport(evt.viewState)}
            interactiveLayerIds={['fishFarmFillLayer', 'fishFarmOutlineLayer', 'fishFarmNameLayer']}
            onMouseMove={onMouseMove}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            attributionControl={true}
            style={{ display: 'flex', width: '100%', height: '100%', position: 'relative', boxSizing: 'border-box' }}
        >
            <NavigationControls
                handleInitialExtent={handleInitialExtent}
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
                onFullscreen={onFullscreen}
                isFullScreen={isFullScreen}
            />
            {location.pathname === '/map-view' && isSmallScreen && (
                <React.Fragment>
                    <ScaleControl style={{ position: 'absolute', right: -300, bottom: -5, zIndex: 2 }} />
                    <MapCursorPosition latitude={cursorPosition.latitude} longitude={cursorPosition.longitude} />
                </React.Fragment>
            )}
            {location.pathname === '/map-view' && <MapLegend />}
            {location.pathname === '/map-view' && isShowTimeSlider && ((dataType !== "WaterQuality")) && (
                <TimeSlider />
            )}
            {fishFarm && !isShowFishFarms && <FishFarmLayer fishFarm={fishFarm} hoveredFeatureName={hoveredFeatureName} />}
            {groupedStations.map((stationGroup: any[], index: number) => (
                <MapMarker stations={stationGroup} key={`station-${stationGroup[0].stationId}-${index}`} />
            ))}
            {popupInfo && selectedStation && (
                <CustomPopup
                    onClose={() => {
                        setPopupInfo(null);
                        setSelectedStation(null);
                    }}
                />
            )}
        </MapGl>
    );
};

export default ReactMapGL;
