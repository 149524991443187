import React from 'react'
import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { useEventView } from '../../../context/event-context/EventContext';

const TableFilter = () => {

    const { eventFilter, setEventFilter } = useEventView()
    const isSmallScreen = useMediaQuery('(min-width: 999px)')

    const handleFilterChange = React.useCallback((event: { target: { value: string; }; }) => {
        setEventFilter(event.target.value.toLowerCase());
    }, [setEventFilter]);

    const startAdornment = (
        <InputAdornment position="start">
            <Search sx={{ color: '#163046', fontSize: isSmallScreen ? '24px' : '16px' }} />
        </InputAdornment>
    );

    return (
        <Box sx={{ width: 'fit-content', borderRadius: 2, border: '1px solid lightgray', padding: '5px' }}>
            <TextField
                id="search-textfield"
                placeholder='Search...'
                variant="outlined"
                value={eventFilter}
                onChange={handleFilterChange}
                InputProps={{
                    startAdornment,
                    style: {
                        padding: 0,
                        height: 20,
                        fontSize: isSmallScreen ? '14px' : '12px'
                    }
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        padding: '0',
                        '& fieldset': {
                            borderColor: 'transparent',
                            padding: 0,
                            m: 0,
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                        '& input::placeholder': {
                            fontSize: isSmallScreen ? '14px' : '12px',
                        },
                        '&.MuiInputBase-input': {
                            padding: '0 !important',
                        },
                    },
                }}
            />
        </Box>
    )
}

export default TableFilter